import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Container, Header } from 'semantic-ui-react';

import AppContext, { AppContextObject } from '../../contexts/app';
import UserInfoContext from '../../contexts/userInfo';
import { UserInfo } from '../../lib/models/userInfo';
import { AlertType } from '../../lib/models/components/alert';
import CommunityPartner from '../../lib/models/communityPartner';
import AcknowledgementFormHelper from '../../lib/helpers/pages/acknowledgementForm';

import DoneeInformation from './DoneeInformation';
import IRCAgreement from './IRCAgreement';
import RepresentativeSignature from './RepresentativeSignature';
import LoadingState from '../../Components/LoadingState/LoadingState';

import './AcknowledgementForm.css';

const AcknowledgementForm = () => {
	const app: AppContextObject = useContext(AppContext);
	const userInfo: UserInfo = useContext(UserInfoContext);
	const navigate = useNavigate();
	const [confirmedAgreements, setConfirmedAgreements] = useState(false);
	const [confirmedSignature, setConfirmedSignature] = useState(false);
	const [signatureTime, setSignatureTime] = useState();
	const [partner, setPartner] = useState({} as CommunityPartner);
	const [taxYear, setTaxYear] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getPartner = async () => {
			try {
				setIsLoading(() => true);
				const { data } = await app.communityTrackerApi.getPartnerProfile(userInfo.foodbankId);
				setPartner(() => data);
				setTaxYear(() => AcknowledgementFormHelper.eligibleTaxYear(data.taxDocuments));
			} catch (error: any) {
				app.handleAlert({
					header: 'Error getting community partner data',
					content: error?.message,
					type: AlertType.ERROR
				});
			} finally {
				setIsLoading(() => false);
			}
		}
		getPartner();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [app, userInfo.foodbankId]);

	const handleSubmitForm = async () => {
		try {
			setIsLoading(() => true);
			const { message } = await app.communityTrackerApi.addAcknowledgment({
				signatureTime,
				taxYear,
				partner
			});
			if (message) throw new Error(message);

			app.handleAlert({
				header: 'Acknowledgement Form Signed',
				content: 'form signed successfully',
				type: AlertType.SUCCESS
			});

			navigate('/profile');
		} catch (error: any) {
			app.handleAlert({
				header: 'Error signing acknowledgment form',
				content: error?.message,
				type: AlertType.ERROR
			});
		} finally {
			setIsLoading(() => false);
		}
	}

	return (
		<>
			{ isLoading && <LoadingState /> }
			{ taxYear ? (
				<Container>
					<Header as='h3' textAlign='center'>
						Donation Acknowledgement Form
					</Header>
					<DoneeInformation
						partner={partner}
						taxYear={taxYear}
					/>
					<IRCAgreement
						onConfirmationChange={setConfirmedAgreements}
					/>
					<RepresentativeSignature
						onConfirmationChange={setConfirmedSignature}
						onSignatureTimeChange={setSignatureTime}
						signatureTime={signatureTime}
					/>
					<div className='acknowledgement-form-button-wrapper'>
						<Button
							primary
							disabled={!confirmedAgreements || !confirmedSignature}
							onClick={handleSubmitForm}
						>
							Submit
						</Button>
					</div>
				</Container>
			) : (
				<Header as='h3' textAlign='center'>
					No documents to sign
				</Header>
			)}
		</>
	)
}

export default AcknowledgementForm;
