import React from 'react';
import { Message } from 'semantic-ui-react';

import { ALERT } from '../../lib/constants/index';
import { AlertProps } from '../../lib/models/components/alert';

import './Alert.css';

const Alert: React.FC<AlertProps> = (props) => {
	const { header, content, type } = props;

	return (
		<div className='alert-message-wrapper'>
			<Message
				header={header}
				content={content}
				color={ALERT.TYPE_TO_COLOR[type]}
			/>
		</div>
	)
}

export default Alert;
