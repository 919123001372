import { CommunityPartner, PickupSiteDetails } from '../../models/communityPartner';

class EditProfileHelper {
	/**
	* Handles changes to profile data
	* @param attr - the name of the field in the profile that has changed
	* @param value - the new value the field takes after a change
	* @param editedData - community partner
	*/
	// TODO: typing
	static onChangeProfileData = (attr: string, newValue: string, editedData: any, fn: Function) => {
		if (attr.split('.').length !== 2) return fn({ ...editedData, [attr]: newValue });

		const [key1, key2] = attr.split('.');
		if (!(key1 in editedData)) return fn({ ...editedData, [attr]: newValue });

		const obj = { [key1]: { ...editedData[key1], [key2]: newValue } }
		return fn({ ...editedData, ...obj });
	}

	// TODO: typing
	static onChange = (attr: string, value: string, editedData: any, setEditedData: Function) => {
		this.onChangeProfileData(attr, value, editedData, setEditedData);
	};

	static onChangeSites = (
		values: PickupSiteDetails, data: CommunityPartner, setEditedData: Function
	) => {
		setEditedData({ ...data, sites: values });
	};

	static deleteSite = (
		editedData: any,
		selectedSiteName: string,
		setEditedData: Function,
		sites: PickupSiteDetails[]
	) => {
		const newSites = sites.filter(({ name }: PickupSiteDetails) => name !== selectedSiteName);
		setEditedData({ ...editedData, sites: newSites });
	};
}

export default EditProfileHelper
