export enum AlertType {
	SUCCESS = 'success',
	WARNING = 'warning',
	INFO = 'info',
	ERROR = 'error'
}

export interface AlertProps {
	readonly header: string;
	readonly type: AlertType;
	readonly content?: string;
}

export const ALERT_TIME_OUT: Record<AlertType, number> = {
	error: 10_000, // 10 seconds
	warning: 6_000, // 6 seconds
	success: 4_000, // 4 seconds
	info: 6_000 // 6 seconds
}
