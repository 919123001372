import React from 'react';
import { Table } from 'semantic-ui-react';

import CommonHelper from '../../lib/helpers/common';
import DateTimeHelper from '../../lib/helpers/dateTime';
import ViewDonationsHelper from '../../lib/helpers/pages/viewDonations';
import { MonthlyViewProps } from '../../lib/models/pages/viewDonations';
import './ViewDonations.css';

const MonthlyView: React.FC<MonthlyViewProps> = ({
	data, startingTimestamp
}: any) => {
	const datePartitions = ViewDonationsHelper.getMonthlyDatePartitions(startingTimestamp);
	const dateHeaders = datePartitions.map(DateTimeHelper.getFormattedMonth);
	const donationsByTimestamp = ViewDonationsHelper.getDonationsByTimestamp(data, datePartitions);

	const tableHeaders = (): JSX.Element => (
		<Table.Header>
			<Table.Row textAlign='center'>
				<Table.HeaderCell>
					Sites
				</Table.HeaderCell>
				{dateHeaders.map((formattedDate) => (
					<Table.HeaderCell key={formattedDate}>
						{formattedDate}
					</Table.HeaderCell>
				))}
			</Table.Row>
		</Table.Header>
	)

	const donationRowPerPartner = (): JSX.Element => (
		<Table.Row className='donation-main-row-selected'>
			<Table.Cell key='site_name'> All </Table.Cell>

			{datePartitions.map((timestamp) => (
				<Table.Cell key={timestamp}>
					{ CommonHelper.humanizeNumber(
						ViewDonationsHelper.getTotalVolume(donationsByTimestamp[timestamp])
					)}
				</Table.Cell>
			))}
		</Table.Row>
	)

	const donationRowsPerSite = (): JSX.Element[] => (
		ViewDonationsHelper.getUniqueSites(data).map((siteName: string) => (
			<Table.Row key={siteName}>
				<Table.Cell>
					{siteName}
				</Table.Cell>
				{datePartitions.map((timestamp) => (
					<Table.Cell key={timestamp}>
						{ CommonHelper.humanizeNumber(
							ViewDonationsHelper.getDonationWeightBySite(
								donationsByTimestamp[timestamp], siteName
							)
						)}
					</Table.Cell>
				))}
			</Table.Row>
		))
	)

	const tableBody = (): JSX.Element => (
		<Table.Body className='donation-table-body'>
			<>
				{donationRowPerPartner()}
				{donationRowsPerSite()}
			</>
		</Table.Body>
	)

	return (
		<div className='weekly-view-container'>
			<Table celled className='donation-table' fixed>
				{tableHeaders()}
				{tableBody()}
			</Table>
		</div>
	)
}

export default MonthlyView;
