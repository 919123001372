import React, {
	useContext, useEffect, useState
} from 'react';
import { Header as TextHeader, Container } from 'semantic-ui-react';

import UserInfoContext from '../../contexts/userInfo';
import AppContext, { AppContextObject } from '../../contexts/app';
import { UserInfo } from '../../lib/models/userInfo';
import CommunityPartner from '../../lib/models/communityPartner';
import COMMUNITY_PARTNER from '../../lib/constants/initialStates/communityPartner';

import SiteSelection from '../../Components/SiteSelection/SiteSelection';

import { AlertType } from '../../lib/models/components/alert';
import LoadingState from '../../Components/LoadingState/LoadingState';
import Footer from '../../Components/Footer/Footer';
import Description from './Description';

import './Home.css';

const Home = () => {
	const [partner, setPartner] = useState(COMMUNITY_PARTNER as CommunityPartner);

	const userInfo: UserInfo = useContext<UserInfo>(UserInfoContext);
	const app: AppContextObject = useContext<AppContextObject>(AppContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const getPartner = async () => {
		try {
			setIsLoading(() => true);
			const { data } = await app.communityTrackerApi.getPartnerProfile(userInfo.foodbankId);
			setPartner(data);
		} catch (error: any) {
			app.handleAlert({
				header: 'Error getting community partner data',
				content: error?.message,
				type: AlertType.ERROR
			});
		} finally {
			setIsLoading(() => false);
		}
	};

	useEffect(() => {
		getPartner();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo.foodbankId]);

	return (
		<Container textAlign='left'>
			{isLoading && <LoadingState />}
			<div className='home-page-container'>
				<TextHeader textAlign='center' as='h2'>
					Amazon Community Donation Capture
				</TextHeader>
				<Description />
				<div className='home-page-pick-up-schedule'>
					<TextHeader textAlign='center' as='h3'>
						{partner.foodbankName}
						&apos;s Amazon pick up schedule
					</TextHeader>
					<SiteSelection
						country={userInfo.country}
						deleteSite={() => {}}
						isEditMode={false}
						onChange={() => {}}
						sites={partner.sites}
						status={partner.status}
						celledTabled
					/>
				</div>
			</div>
			<Footer />
		</Container>
	);
}

export default Home;
