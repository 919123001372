import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useCookies } from 'react-cookie';

import {
	Grid, Segment, Form, Message, Header, Image
} from 'semantic-ui-react';
import { AlertType } from '../../lib/models/components/alert';
import { ALERT, COGNITO, COOKIES } from '../../lib/constants/index';

import ForgotPassword from './ForgotPassword';
import SignInUi from './SignInUI';
import CompletePassword from './CompletePassword';

import './SignIn.css';

interface SignInProps {
	readonly onSignIn: Function;
}

const SignIn: React.FC<SignInProps> = ({ onSignIn }) => {
	const location = useLocation();
	const [, setCookie] = useCookies();

	const [cognitoUser, setCognitoUser] = useState('');
	const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState({ content: '', type: '' });

	const onUpdateAlertMessage = (content: string, type?: AlertType) => {
		setAlertMessage(() => ({
			content,
			type: type ?? AlertType.ERROR
		}));
	}

	const onSignInClick = async (emailInput: string, passwordInput: string) => {
		try {
			setLoading(() => true);
			const user = await Auth.signIn(emailInput, passwordInput);
			const { challengeName, signInUserSession } = user;

			if (challengeName === COGNITO.CHALLENGES.NEW_PASSWORD_REQUIRED) {
				setIsNewPasswordRequired(true);
				setCognitoUser(user);
			} else if (signInUserSession) {
				const { idToken, refreshToken, accessToken } = signInUserSession;
				setCookie(COOKIES.NAMES.ID_TOKEN, idToken.jwtToken, {
					path: '/',
					expires: new Date(idToken.payload.exp * 1000)
				});
				setCookie(COOKIES.NAMES.ACCESS_TOKEN, accessToken.jwtToken, {
					path: '/',
					expires: new Date(accessToken.payload.exp * 1000)
				});
				setCookie(COOKIES.NAMES.REFRESH_TOKEN, refreshToken.token, { path: '/' });
				onSignIn();
			}
		} catch (error: any) {
			onUpdateAlertMessage(error.message);
		} finally {
			setLoading(() => false);
		}
	}

	const onCompletePassword = async (newPassword: string) => {
		try {
			setLoading(() => true);
			await Auth.completeNewPassword(cognitoUser, newPassword);
			onSignIn();
		} catch (error: any) {
			onUpdateAlertMessage(error.message);
		} finally {
			setLoading(() => false);
		}
	}

	const renderForm = () => {
		if (location.pathname === '/password-recovery') {
			return (
				<ForgotPassword
					onAlert={onUpdateAlertMessage}
				/>
			);
		}

		if (isNewPasswordRequired) {
			return (
				<CompletePassword
					onSubmit={onCompletePassword}
					onAlert={onUpdateAlertMessage}
					loading={loading}
				/>
			);
		}

		return (
			<SignInUi
				onSubmit={onSignInClick}
				onAlert={onUpdateAlertMessage}
				loading={loading}
			/>
		);
	}

	return (
		<Grid textAlign='center'>
			<Grid.Column style={{ marginTop: '25vh', maxWidth: 600 }}>
				<Segment inverted>
					<div className='login-header'>
						<Image src='/amazon-arrow.png' />
						<span>
							<Header as='h2'>Amazon Donation Capture</Header>
						</span>
					</div>
				</Segment>
				<Segment>
					<Form>
						{ alertMessage.content && (
							<Message
								content={alertMessage.content}
								color={ALERT.TYPE_TO_COLOR[alertMessage.type as AlertType]}
							/>
						)}

						{renderForm()}
					</Form>
				</Segment>
			</Grid.Column>
		</Grid>
	)
}

export default SignIn;
