import { DAYS_MAPPING, MONTHS_MAPPING } from '../constants/dateTime';

class DateTimeHelper {
	/**
	 * @description provided an epoch time returns a string with month and year
	 * @example
	 * 	epoch = 1670280508578
	 * 	monthYear = '12 / 2022'
	 * @param {number} epoch
	 * @returns {string} monthYear
	 */
	static epochToMonthYear = (epoch: number): string => {
		const date: Date = new Date(epoch);
		const year: number = date.getFullYear();
		const month: number = date.getMonth();

		return `${month + 1} / ${year}`;
	}

	/**
	 * @description provided an epoch time returns the date string representation
	 * @example
	 *  epoch = 1670280508578
	 * 	monthYear = 'Mon Dec 05 2022'
	 * @param {number} epoch
	 * @returns {string} dateString
	 */
	static epochToDateString = (epoch: number): string => {
		const date: Date = new Date(epoch);

		return date.toDateString();
	}

	/**
	 * @description provided an epoch time returns the locale string representation
	 * @example
	 *  epoch = 1670280508578
	 * 	monthYear = '12/5/2022, 2:48:28 PM'
	 * @param {number} epoch
	 * @returns {string} dateString
	 */
	static epochToLocaleString = (epoch: number): string => {
		const date: Date = new Date(epoch);

		return date.toLocaleString();
	}

	/**
	 * @description provided an epoch time returns the year month day representation
	 * @example
	 * 	epoch = 1670280508578
	 * 	yearMonthDay = '2022-12-05'
	 * @param {number} epoch
	 * @returns {string} yearMonthDay
	 */
	static getYYYYMMDD = (epoch: number): string => {
		const newDate: Date = new Date(epoch);
		const year: string = String(newDate.getFullYear());
		let month: string = String(newDate.getMonth() + 1);
		let day: string = String(newDate.getDate());

		if (month.length === 1) month = `0${month}`;
		if (day.length === 1) day = `0${day}`;

		return `${year}-${month}-${day}`;
	}

	/**
	 * @description returns the date of the end of today
	 * @example
	 *  endOfTodayDate = Mon Dec 05 2022 23:59:59 GMT-0800 (Pacific Standard Time)
	 * @returns endOfTodayDate
	 */
	static getEndOfTodayDate = (): Date => {
		const todayFormattedDate: string = this.getYYYYMMDD(new Date().getTime());
		const endOfTodayDate: Date = new Date(`${todayFormattedDate} 23:59:59`);
		return endOfTodayDate;
	}

	/**
	 * @description returns the epoch time of the end of today
	 * @example
	 *  firstDayOfYearEpoch = 1670313599000
	 * @returns firstDayOfYearEpoch
	 */
	static getEndOfTodayEpoch = (): number => (
		this.getEndOfTodayDate().getTime()
	)

	/**
	 * @description provided an epoch time returns the date of the first day for that year
	 * @example
	 * 	epoch = 1670280508578
	 *  firstDayOfYearDate = Sat Jan 01 2022 00:00:00 GMT-0800 (Pacific Standard Time)
	 * @param {number} epoch
	 * @returns firstDayOfYearDate
	 */
	static getFirstDayOfYearDate = (epoch: number): Date => (
		new Date(`${new Date(epoch).getFullYear()}-01-01 00:00:00`)
	)

	/**
	 * @description provided an epoch time returns the epoch of the first day for that year
	 * @example
	 * 	epoch = 1670280508578
	 *  firstDayOfYearEpoch = 1641024000000
	 * @param {number} epoch
	 * @returns firstDayOfYearEpoch
	 */
	static getFirstDayOfYearEpoch = (epoch?: number): number => {
		const time: number = epoch ?? new Date().getTime();
		return this.getFirstDayOfYearDate(time).getTime();
	}

	/**
	 * @description provided an epoch time returns the beginning timestamp of that day
	 * @example
	 * 	epoch = 1670280508578
	 * 	floorTimestamp = 1670227200000
	 * @param {number} epoch
	 * @returns {number} floorTimestamp
	 */
	static getFloorDateTimestamp = (epoch: number): number => {
		const yyyyMmDd: string = this.getYYYYMMDD(epoch);

		return new Date(`${yyyyMmDd} 00:00:00`).getTime();
	}

	/**
	 * @description provided an epoch time returns the date formatted as 'day (month. day)'
	 * @example
	 * 	epoch = 1670280508578
	 * 	formattedDate = 'Monday (Dec. 5)'
	 * @param {number} epoch
	 * @returns {string} formattedDate
	 */
	static getFormattedDate = (epoch: number): string => {
		const newDate: Date = new Date(epoch);
		const dayOfTheWeek: string = DAYS_MAPPING[newDate.getDay()];
		const month: string = MONTHS_MAPPING[newDate.getMonth() + 1];
		const day: string = String(newDate.getDate());
		return `${dayOfTheWeek} (${month}. ${day})`;
	}

	/**
	 * @description provided an epoch time returns the date formatted as 'month'
	 * @example
	 * 	epoch = 1670280508578
	 * 	formattedDate = 'Dec'
	 * @param {number} epoch
	 * @returns {string} formattedDate
	 */
	static getFormattedMonth = (epoch: number): string => {
		const newDate: Date = new Date(epoch);
		const month: string = MONTHS_MAPPING[newDate.getMonth() + 1];
		return month;
	}
}

export default DateTimeHelper;
