import React, {
	useContext, useEffect, useState
} from 'react';

import { Container, Header } from 'semantic-ui-react';

import UserInfoContext from '../../contexts/userInfo';
import AppContext, { AppContextObject } from '../../contexts/app';
import { UserInfo } from '../../lib/models/userInfo';
import CommunityPartner from '../../lib/models/communityPartner';

import './ReportDonation.css';

import { DonationEntry } from '../../lib/models/pages/reportDonations';
import DonationEntriesList from './DonationEntriesList';
import NewDonationEntry from './NewDonationEntry';
import { AlertType } from '../../lib/models/components/alert';
import LoadingState from '../../Components/LoadingState/LoadingState';

// TODO: add logic to bulk upload from a csv file
const ReportDonation = () => {
	const userInfo: UserInfo = useContext(UserInfoContext);
	const app: AppContextObject = useContext(AppContext);
	const [partner, setPartner] = useState({} as CommunityPartner);
	const [donationEntries, setDonationEntries] = useState([] as DonationEntry[])
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getPartner = async () => {
			try {
				setIsLoading(() => true);
				const { data } = await app.communityTrackerApi.getPartnerProfile(userInfo.foodbankId);
				setPartner(() => data);
			} catch (error: any) {
				app.handleAlert({
					header: 'Error getting community partner data',
					content: error?.message,
					type: AlertType.ERROR
				});
			} finally {
				setIsLoading(() => false);
			}
		}
		getPartner();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo.foodbankId]);

	const handleAddDonationEntry = (donationEntry: DonationEntry) => {
		setDonationEntries((prev) => ([...prev, donationEntry]));
	}

	const handleDeleteEntry = (index: number) => {
		setDonationEntries((prev) => prev.filter((_entry: DonationEntry, position: number) => (
			position !== index
		)));
	}

	const handleSubmitReport = async () => {
		try {
			setIsLoading(() => true);
			await app.communityTrackerApi.addReportDonations(donationEntries);
			setDonationEntries(() => []);
			app.handleAlert({
				header: 'Success',
				content: 'Donation entries created successfully',
				type: AlertType.SUCCESS
			});
		} catch (error: any) {
			app.handleAlert({
				header: 'Error creating donation entries',
				content: error?.message,
				type: AlertType.ERROR
			});
		} finally {
			setIsLoading(() => false);
		}
	}

	return (
		<div className='report-donation-wrapper'>
			{ isLoading ? (
				<LoadingState />
			) : (
				<Container textAlign='center'>
					<Header as='h3'>
						{`Report Donations For ${partner.foodbankName}`}
					</Header>
					<DonationEntriesList
						donationEntries={donationEntries}
						onDeleteEntry={handleDeleteEntry}
						onSubmitReport={handleSubmitReport}
					/>
					<NewDonationEntry
						partner={partner}
						handleAddDonationEntry={handleAddDonationEntry}
					/>
				</Container>
			)}
		</div>
	)
}

export default ReportDonation;
