interface WeightLimits {
	DAILY: {
		MIN: number;
		MAX: number;
	},
	MONTHLY: {
		MIN: number;
		MAX: number;
	}
}

const WEIGHTS: WeightLimits = {
	DAILY: {
		MIN: 0,
		MAX: 10_000
	},
	MONTHLY: {
		MIN: 0,
		MAX: 1_000_000
	}
}

export default WEIGHTS;
