import React, { useContext, useState } from 'react';

import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import SiteSearchDropDownHelper from '../../lib/helpers/components/siteSearchDropDown';
import AppContext, { AppContextObject } from '../../contexts/app';
import { SiteSearchDropDownProps } from '../../lib/models/components/siteSearchDropDown';

const SiteSearchDropdown = ({ country, sites, onChange }: SiteSearchDropDownProps) => {
	const { t } = useTranslation();
	const app: AppContextObject = useContext(AppContext);
	const [isFetching, setFetching] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [options, setOptions] = useState([]);
	const values = SiteSearchDropDownHelper.getValues(sites);

	const stickyOptions = SiteSearchDropDownHelper.mapSiteDetails(sites);
	const stickyKeys = stickyOptions.map(({ key }) => key);
	const filteredOptions = options.filter(({ key }) => !stickyKeys.includes(key));
	const finalOptions = [...stickyOptions, ...filteredOptions];

	const handleSearchChange = (
		_e: React.SyntheticEvent,
		{ searchQuery: searchQueryInput }: { searchQuery: string }
	) => {
		setSearchQuery(searchQueryInput);
		SiteSearchDropDownHelper.getOptions(
			app, country, searchQueryInput, setFetching, setOptions, stickyOptions, t
		);
	}

	const onChangeInput = (_e: React.SyntheticEvent, { value }: any) => {
		onChange(value, finalOptions);
		setSearchQuery('');
	}

	return (
		<>
			<Dropdown
				fluid
				multiple
				selection
				options={finalOptions}
				placeholder='Add Sites'
				value={values}
				onChange={onChangeInput}
				search
				searchQuery={searchQuery}
				onSearchChange={handleSearchChange}
				loading={isFetching}
			/>
		</>
	);
};

export default SiteSearchDropdown;
