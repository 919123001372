import { DropdownItemProps } from 'semantic-ui-react';
import { DONATIONS } from '../../constants';
import { NUM_DAYS_PER_WEEK, ONE_DAY_IN_MILLISECONDS, ONE_SECOND_IN_MILLISECONDS } from '../../constants/dateTime';
import { DonationData, DonationsByTimestamp } from '../../models/pages/viewDonations';
import DateTimeHelper from '../dateTime';

class ViewDonationsHelper {
	/**
	 * @description converts the string array collection into a dropdown items options array
	 * @param {string[]} collection
	 * @returns {DropdownItemProps[]} drop down items array
	 */
	static getDropdownOptions = (collection: string[]): DropdownItemProps[] => (
		collection.map((dropdown: string) => ({
			key: dropdown,
			value: dropdown,
			text: dropdown
		}))
	)

	/**
	 * @description converts the donation types into a dropdown items options array
	 * @returns {DropdownItemProps[]} drop down items array
	 */
	static getDonationTypeOptions = (): DropdownItemProps[] => (
		Object.entries(DONATIONS.TYPES).map(([type, label]: [string, string]) => ({
			key: type,
			value: type,
			text: label
		}))
	)

	/**
	 * @description
	 *   provided a start and end timestamps
	 *   it returns the timestamps for the days in that range
	 * @param {number} startTimestamp
	 * @param {number} endingTimestamp
	 * @returns {number[]} daysRange
	 */
	static getDatePartitions = (startTimestamp: number, endingTimestamp: number): number[] => {
		const daysRange: number[] = [];

		for (let i = startTimestamp; i <= endingTimestamp; i += ONE_DAY_IN_MILLISECONDS) {
			daysRange.push(i);
		}

		return daysRange;
	}

	/**
	 * @description
	 * 	provided a timestamp it returns the timestamps for the start of every month in that year
	 * @example
	 *  timestamp = 1641024000000
	 * 	monthsRange = [1641024000000,1643702400000,1646121600000,...,1669881600000]
	 * @param {number} timestamp
	 * @returns {number[]} monthsRange (array of size 12)
	 */
	static getMonthlyDatePartitions = (timestamp: number): number[] => {
		const monthsRange: number[] = [];
		const year: string = String(new Date(timestamp).getFullYear());

		for (let month = 1; month <= 12; month += 1) {
			monthsRange.push(new Date(`${year}-${String(month).padStart(2, '0')}-01 00:00:00`).getTime());
		}

		return monthsRange;
	}

	/**
	 * @description timestamp of 7 days prior to given timestamp
	 * @param {number} timestampValue
	 * @returns {number} startingTimestamp
	 */
	static getStartingTimestamp = (timestampValue: number): number => (
		timestampValue - (
			NUM_DAYS_PER_WEEK * ONE_DAY_IN_MILLISECONDS
		) + ONE_SECOND_IN_MILLISECONDS
	)

	/**
	 * @param {DonationData[]} items
	 * @returns {number} sum of donation weights
	 */
	static getTotalVolume = (items: DonationData[]): number => (
		items?.reduce((accumulator: number, item: DonationData) => accumulator + item.weight, 0)
	)

	/**
	 * @description
	 * 	converts donation data into a map with donation timestamp as keys
	 *  and donations arrays as values
	 * @param {DonationData[]} data
	 * @param {number[]} datePartitions
	 * @returns {number[]} donationsByTimeStamp
	 */
	static getDonationsByTimestamp = (data: DonationData[], datePartitions: number[]) => {
		const initialDonationsMap: DonationsByTimestamp = datePartitions
			?.reduce((prev: DonationsByTimestamp, timestamp: number) => ({
				...prev,
				...{ [timestamp]: [] }
			}), {});

		const donationByTimeStamp: DonationsByTimestamp = data
			?.reduce((donationMap: DonationsByTimestamp, donation: DonationData) => {
				const donatedAtKey: number = DateTimeHelper.getFloorDateTimestamp(donation.donatedAt);

				if (!donationMap[donatedAtKey]) {
					// Should never enter here
					console.error('No key found for in weekly view', donationMap, donatedAtKey, donation.donatedAt);
					return donationMap;
				}

				donationMap[donatedAtKey].push(donation);
				return donationMap;
			}, initialDonationsMap);

		return donationByTimeStamp;
	}

	/**
	 * @param {DonationsData[]} donations
	 * @param {string} siteName
	 * @returns {number} weight
	 */
	static getDonationWeightBySite = (donations: DonationData[], siteName: string): number => {
		const siteDonations: DonationData | undefined = donations.find((donation: DonationData) => (
			donation.siteName === siteName
		));

		return siteDonations?.weight ?? 0;
	}

	/**
	 * @description returns an array of unique site names for an array of donations
	 * @param {DonationData[]} donations
	 * @returns {string[]} uniqueSites
	 */
	static getUniqueSites = (donations: DonationData[]): string[] => {
		const sites: string[] = donations.map((donation: DonationData) => donation.siteName);
		const uniqueSites: string[] = Array.from(new Set(sites));

		return uniqueSites;
	}
}

export default ViewDonationsHelper;
