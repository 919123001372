import React from 'react';
import {
	Form, Grid, Button
} from 'semantic-ui-react';

import { AdditionalLinkProps } from '../../lib/models/pages/additionalLink';

const AdditionalLink = ({
	additionalLink,
	index,
	isEditMode,
	onChangeLink,
	handleDeleteLink,
	handleAddLink,
	deleteDisabled
}: AdditionalLinkProps) => {
	const createDisplayLink = () => (
		<>
			{additionalLink
				&& (
					<div className='website-url-div'>
						<a
							className='label-value'
							href={additionalLink}
							target='_blank'
							rel='noreferrer'
						>
							{additionalLink || '-'}
						</a>
					</div>
				)}
		</>
	)

	const createEditLink = () => (
		<Grid columns={2}>
			<Grid.Column>
				<Form.Input
					fluid
					icon='linkify'
					iconPosition='left'
					placeholder='URL'
					value={additionalLink}
					onChange={(e) => onChangeLink(index, e.target.value)}
				/>
			</Grid.Column>
			<Grid.Column>
				<Button
					color='green'
					onClick={(e) => handleAddLink((e.target as HTMLButtonElement).value)}
				>
					+
				</Button>
				<Button
					color='red'
					disabled={deleteDisabled}
					onClick={() => handleDeleteLink(index)}
				>
					-
				</Button>
			</Grid.Column>
		</Grid>
	)

	return (
		<>
			{isEditMode ? createEditLink() : createDisplayLink()}
		</>
	)
};

export default AdditionalLink;
