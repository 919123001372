import { SiteDaysMapping } from '../../models/components/siteSelection';
import REQUEST_STATES from '../../constants/request';
import { SiteDetailResponse } from '../../models/siteDetail';
import { AppContextObject } from '../../../contexts/app';
import { PickupSiteDetails, SiteOption } from '../../models/communityPartner';

class SiteSelectionHelper {
	static getSiteNames = (sites: PickupSiteDetails[]) => sites.map(
		({ name }: { name: string }) => name
	);

	static getSitesDetails = async (
		AlertType: any, app: AppContextObject, setSiteDetails: Function, siteNames: string[]
	) => {
		try {
			const sitesData = await app.communityTrackerApi.getSitesDetails(siteNames);
			/* eslint-disable array-callback-return */
			sitesData.map(({ data }: SiteDetailResponse) => {
				setSiteDetails((prev: PickupSiteDetails) => ({
					...prev,
					[data.name]: {
						name: data.name, address: data.address, type: data.type
					}
				}))
			});
		} catch (error: any) {
			app.handleAlert({
				header: 'Error getting community partner data',
				content: error?.message,
				type: AlertType.ERROR
			})
		}
	}

	static onPickupDayChange = (
		onChange: Function, siteName: string, sites: PickupSiteDetails[], values: string[]
	) => {
		const sitesObject = sites.map((site: PickupSiteDetails) => {
			if (site.name === siteName) {
				return { ...site, ...{ days: values } };
			}
			return site;
		});
		onChange(sitesObject);
	};

	// sites is a slice of the partner state that represents the sites
	static onSiteSelection = (
		onChange: Function, options: SiteOption[], sites: PickupSiteDetails[], values: string[]
	) => {
		// this generates a dict where the key is the sitename and the value the business type
		const optionTypeMapping = options
			// TODO: typing
			.reduce((prev: any, acc: any) => ({ ...prev, ...{ [acc.value]: acc.type } }), {});
		// this generates a dict where the key is
		// the site name and the value an array of the pickup days
		// this is only generated for the "previously" selected sites
		const sitesDaysMapping: SiteDaysMapping = sites
			.reduce((prev: SiteDaysMapping, site: PickupSiteDetails) => (
				{ ...prev, ...{ [site.name]: site.days } }), {});

		// this last instruction molds the sites into the state they're saved in within the partner
		const sitesObject = values.map((name: string) => ({
			type: optionTypeMapping[name],
			name,
			days: sitesDaysMapping[name] || []
		}));
		// update the state
		onChange(sitesObject);
	}

	static newKeys = (sites: PickupSiteDetails[], siteDetails: PickupSiteDetails) => {
		const newKeys = sites
			.filter(({ name }: { name: string }) => !Object.prototype.hasOwnProperty.call(
				siteDetails, name
			))
			.map(({ name }: { name: string }) => name);
		return newKeys;
	}

	// TODO: typing
	static newSiteDetailObjects = (newKeys: string[]) => {
		const newSiteDetailObjects = newKeys.reduce((prev: any, curr: any) => ({
			...prev,
			[curr]: { state: REQUEST_STATES.FETCH_START_PENDING, data: {} }
		}), {});
		return newSiteDetailObjects;
	}
}

export default SiteSelectionHelper;
