import { Months } from '../../constants/dateTime';
import { TaxDocument } from '../../models/communityPartner';

class AcknowledgementFormHelper {
	/**
	 * @description
	 * 	checks if we're in the current year's acknowledgement period and if the documents
	 *  for it has been already submitted, if not returns the tax year to sign.
	 * @param {TaxDocument[]} taxDocuments
	 * @returns eligibleTaxYear
	 */
	static eligibleTaxYear = (taxDocuments: TaxDocument[]): string => {
		const currentDate: Date = new Date();
		const currentYear: number = currentDate.getFullYear();
		const currentMonth: number = currentDate.getMonth() + 1;

		// if not in the acknowledgement period you have no documents to sign
		const acknowledgementPeriod: Months[] = [Months.DEC, Months.JAN, Months.FEB, Months.MAR];
		if (!acknowledgementPeriod.includes(currentMonth)) return '';

		// acknowledgement is for the previous tax year
		const currentTaxYear: number = currentMonth === Months.DEC ? currentYear : currentYear - 1;

		// if tax document already signed you have no more documents to sign
		const taxYearDocument: TaxDocument | undefined = taxDocuments?.find((document) => (
			document.year === currentTaxYear
		));
		if (taxYearDocument) return '';

		return String(currentTaxYear);
	}
}

export default AcknowledgementFormHelper;
