import axios from 'axios';
import DevSettings from './devSettings';

export const isLocalHost = () => window.location.origin.indexOf('community-donations.amazon') === -1;

export interface CloudFrontSettings {
	readonly apiUrl: string;
	readonly region: string;
	readonly authenticationFlowType: string;
	readonly userPoolId: string;
	readonly userPoolWebClientId: string;
	readonly mandatorySignIn: string;
}

const getApiUrl = async (): Promise<string> => {
	if (isLocalHost()) {
		return `https://api.${process.env.REACT_APP_DEV_USER}.dev.na.community-donations.amazon`;
	}

	return window.location.origin.replace('https://', 'https://api.');
}

const getAuthConfig = async (): Promise<CloudFrontSettings> => {
	if (isLocalHost()) return DevSettings;

	try {
		const { data } = await axios.get('/settings.json');
		if (data) return data as CloudFrontSettings;
	} catch (error: any) {
		console.error(error?.message);
	}

	return {} as CloudFrontSettings;
}

const config = {
	apiUrl: getApiUrl(),
	authConfig: getAuthConfig()
}

export default config;
