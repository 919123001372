class AdditionalLinksHelper {
	static handleChangeLink = (
		additionalLinks: string[],
		index: number,
		onChange: Function,
		value: string
	) => {
		const newArray = [...additionalLinks];
		newArray[index] = value;
		onChange(newArray);
	};

	static handleDeleteLink = (index: number, additionalLinks: string[], onChange: Function) => {
		const newArray = [...additionalLinks];
		newArray.splice(index, 1);
		onChange(newArray);
	}

	static handleAddLink = (link: string, additionalLinks: string[], onChange: Function) => {
		const newArray = [...additionalLinks, link];
		onChange(newArray);
	}
}

export default AdditionalLinksHelper;
