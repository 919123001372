import React, {
	useContext, useEffect, useState
} from 'react';

import { Container } from 'semantic-ui-react';

import UserInfoContext from '../../contexts/userInfo';
import AppContext, { AppContextObject } from '../../contexts/app';
import { UserInfo } from '../../lib/models/userInfo';
import CommunityPartner from '../../lib/models/communityPartner';
import COMMUNITY_PARTNER from '../../lib/constants/initialStates/communityPartner';

import './Profile.css';

import { AlertType } from '../../lib/models/components/alert';
import LoadingState from '../../Components/LoadingState/LoadingState';
import EditProfile from './EditProfile';

const Profile = () => {
	const [partner, setPartner] = useState(COMMUNITY_PARTNER as CommunityPartner);

	const userInfo: UserInfo = useContext(UserInfoContext);
	const app: AppContextObject = useContext(AppContext);
	const [isLoading, setIsLoading] = useState(false);
	const [isEditMode, setEditState] = useState(false);

	const getPartner = async () => {
		try {
			setIsLoading(() => true);
			const { data } = await app.communityTrackerApi.getPartnerProfile(userInfo.foodbankId);
			setPartner(data);
		} catch (error: any) {
			app.handleAlert({
				header: 'Error getting community partner data',
				content: error?.message,
				type: AlertType.ERROR
			});
		} finally {
			setIsLoading(() => false);
		}
	};

	useEffect(() => {
		getPartner();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo.foodbankId]);

	return (
		<Container textAlign='left'>
			{isLoading && <LoadingState />}
			<div className='profile-page-content-container'>
				<EditProfile
					data={partner}
					isEditMode={isEditMode}
					setEditState={setEditState}
					getFreshData={() => getPartner()}
				/>
			</div>
		</Container>
	);
};

export default Profile;
