import axios from 'axios';

import CommunityPartner, { PartnerProfileResponse } from '../lib/models/communityPartner';
import { DonationEntry } from '../lib/models/pages/reportDonations';
import { SiteDetailResponse, SitesDetailResponse, SiteQueryParams } from '../lib/models/siteDetail';
import { GetReportDonationsParams } from '../lib/models/pages/viewDonations';

type DocumentData = {
	file: string;
	contentType: string;
}

class CommunityTrackerApi {
	private readonly apiUrl: string;

	constructor(apiUrl: string) {
		this.apiUrl = apiUrl;
	}

	getPartnerProfile = async (id: string): Promise<PartnerProfileResponse> => {
		try {
			const { data } = await axios.get(`${this.apiUrl}/partner/${id}`);
			return data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	addReportDonations = async (donations: DonationEntry[]) => {
		try {
			const response = await axios.post(`${this.apiUrl}/donations`, donations);
			return response;
		} catch (error: any) {
			throw new Error(error?.response?.data?.message || error?.message);
		}
	}

	getReportDonations = async (queryParams: GetReportDonationsParams) => {
		try {
			const { data } = await axios.get(`${this.apiUrl}/donations`, { params: queryParams });
			return data;
		} catch (error: any) {
			throw new Error(error?.response?.data?.message || error?.message);
		}
	}

	editPartnerProfile = async (partner: CommunityPartner) => {
		try {
			const { data: responseData } = await axios.put(`${this.apiUrl}/partner/${partner.id}`, partner);
			const { data, statusCode } = responseData;

			if (statusCode !== 200) {
				const issue: string = JSON.parse(data).message;
				throw new Error(issue);
			}

			return responseData;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	getSitesDetails = async (siteNames: string[]): Promise<SiteDetailResponse[]> => Promise.all(
		siteNames.map(async (siteName: string) => {
			try {
				const { data } = await axios.get(`${this.apiUrl}/site/${siteName}`);
				return data;
			} catch (error: any) {
				throw new Error(error.message);
			}
		})
	);

	getSites = async (queryParams: SiteQueryParams): Promise<SitesDetailResponse> => {
		try {
			const { data } = await axios.get(`${this.apiUrl}/site`, { params: queryParams });
			return data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	addAcknowledgment = async (body: any) => {
		try {
			const { data } = await axios.post(`${this.apiUrl}/documents/acknowledgement`, body);
			return data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	downloadTaxDocument = async (s3Key: string): Promise<DocumentData> => {
		try {
			const data = await this.downloadDocument(s3Key, 'tax_document');
			return data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	downloadDocument = async (s3Key: string, type: string): Promise<DocumentData> => {
		try {
			const url = `${this.apiUrl}/documents/s3?key=${encodeURIComponent(s3Key)}&type=${type}`;
			const { data } = await axios.get(url);
			return data;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}

	// NOTE: this is calling the editPartner lambda handler with additional parameters.
	uploadDocument = async (
		partnerId: string, base64Data: string | ArrayBuffer | null, selectedFile: any
	) => {
		const params = {
			document: {
				data: base64Data,
				name: selectedFile.name
			}
		}
		try {
			const url = `${this.apiUrl}/documents/s3/${partnerId}`;
			const response = await axios.put(url, params);
			return response;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
}

export default CommunityTrackerApi;
