class CommonHelper {
	/**
	 * @description humanizes a given number for ease of read
	 * @example
	 * 	number = 123456789
	 *  humanizedNumber = '123,456,789'
	 * @param {number} number
	 * @returns {string} humanizedNumber
	 */
	static humanizeNumber = (number: number): string => Intl.NumberFormat().format(number);
}

export default CommonHelper;
