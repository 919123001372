import { AlertType } from '../../models/components/alert';
import { AlertMessage } from '../../models/components/app';

const ALERT_MESSAGE: AlertMessage = {
	header: '',
	content: '',
	type: AlertType.SUCCESS
}

export default ALERT_MESSAGE;
