import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { Auth } from 'aws-amplify';
import { enable, disable, isEnabled } from 'darkreader';
import {
	Button, Image, Menu, MenuItemProps
} from 'semantic-ui-react'

import UserInfoContext from '../../contexts/userInfo';
import DARK_MODE_THEME from '../../lib/constants/darkmode';
import NavbarMb from './NavbarMb'
import NavbarLg from './NavbarLg'

import './Navbar.css';

export const Navbar = () => {
	const userInfo = useContext(UserInfoContext);
	const [activeItem, setActiveItem] = useState('home')
	const largeMedia: boolean = useMediaQuery({ query: '(min-width:576px)' })

	const handleClickItem = (_event: any, { name }: MenuItemProps) => setActiveItem(() => name!);
	const classIfActive = (name: string) => (activeItem === name ? 'active-navbar' : '');
	const toggleDarkMode = () => (isEnabled() ? disable() : enable(DARK_MODE_THEME));

	const signOut = async (): Promise<void> => {
		await Auth.signOut();
		window.location.reload();
	}

	const renderLinks = (): JSX.Element => (
		<>
			<Menu.Item target='_blank'>
				{`Logged in as ${userInfo.name}`}
			</Menu.Item>
			<Menu.Item
				name='home'
				as={Link}
				to='/'
				className={classIfActive('home')}
				onClick={handleClickItem}
			>
				{largeMedia && (
					<Image
						src='/GSF-Community-logo.jpg'
						className='logo'
					/>
				)}
				Home
			</Menu.Item>
			<Menu.Item
				name='profile'
				as={Link}
				to='/profile'
				className={classIfActive('profile')}
				onClick={handleClickItem}
			/>
			<Menu.Item
				name='view donations'
				as={Link}
				to='/donation/view'
				className={classIfActive('view donations')}
				onClick={handleClickItem}
			/>
			<Menu.Item
				name='record donation volume'
				as={Link}
				to='/donation/add'
				className={classIfActive('add donations')}
				onClick={handleClickItem}
			/>
			<Menu.Item
				name='acknowledgement form'
				as={Link}
				to='/acknowledgement'
				className={classIfActive('acknowledgement form')}
				onClick={handleClickItem}
			/>
			<Menu.Item position='right'>
				{largeMedia ? (
					<Button
						icon='lightbulb'
						onClick={toggleDarkMode}
					/>
				) : (
					<Button onClick={toggleDarkMode}>
						Toggle dark mode
					</Button>
				)}
			</Menu.Item>
			<Menu.Item>
				<Button onClick={signOut}>
					Sign out
				</Button>
			</Menu.Item>
		</>
	)

	return (
		<div>
			{largeMedia ? (
				<NavbarLg renderLinks={renderLinks} />
			) : (
				<NavbarMb renderLinks={renderLinks} />
			)}
		</div>
	)
}

export default Navbar;
