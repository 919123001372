import React, { useContext, useState } from 'react';
import { Button } from 'semantic-ui-react';

import UserInfoContext from '../../contexts/userInfo';
import DateTimeHelper from '../../lib/helpers/dateTime';
import { UserInfo } from '../../lib/models/userInfo';

interface RepresentativeSignatureProps {
	onConfirmationChange: Function;
	onSignatureTimeChange: Function;
	signatureTime: number | undefined;
}

const RepresentativeSignature: React.FC<RepresentativeSignatureProps> = (props) => {
	const { onConfirmationChange, onSignatureTimeChange, signatureTime } = props;
	const userInfo: UserInfo = useContext(UserInfoContext);
	const [signed, setSigned] = useState(false);

	const handleSignature = () => {
		setSigned(() => true);
		onConfirmationChange(true);
		onSignatureTimeChange(new Date().getTime());
	}

	return (
		<>
			<div className='representative-name-wrapper'>
				<div>
					<span className='representative-name'>
						{userInfo.name}
					</span>
				</div>
				{userInfo.title}
				<br />
				<span className='underline-name'>
					Authorized Representative of Donee (Print)
				</span>
			</div>
			<div className='representative-signature-wrapper'>
				<span className='representative-signature'>
					{signed ? (
						<span className='representative-signature-signed'>
							{userInfo.name}
						</span>
					) : (
						<Button
							className='representative-signature-button'
							onClick={handleSignature}
						>
							Sign Here
						</Button>
					)}
				</span>
				<br />
				<span className='underline-signature'>
					Signature of Authorized Representative (Signature)
				</span>
			</div>
			{signed && (
				<div className='representative-signature-date'>
					{`Date Signed: ${DateTimeHelper.epochToLocaleString(signatureTime!)}`}
				</div>
			)}
		</>
	)
}

export default RepresentativeSignature;
