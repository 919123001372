/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Button, Form, Header } from 'semantic-ui-react';
import { COGNITO } from '../../lib/constants/index';
import { AlertType } from '../../lib/models/components/alert';

interface ForgotPasswordProps {
	readonly onAlert: Function;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onAlert }) => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
	const [recoveryCode, setRecoveryCode] = useState('');
	const [codeSent, setCodeSent] = useState(false);
	const [loading, setLoading] = useState(false);

	const onSendRecoveryCode = async () => {
		if (!email) return onAlert('Provide recovery email');

		try {
			setLoading(() => true);
			const data = await Auth.forgotPassword(email);

			if (COGNITO.CODE_DELIVERY_DETAILS in data) {
				setCodeSent(() => true);
				onAlert('Recovery code sent', AlertType.INFO);
			}
		} catch (error: any) {
			onAlert(error.message);
		} finally {
			setLoading(() => false);
		}
	}

	const onChangePassword = async () => {
		if (newPassword !== newPasswordConfirmation) return onAlert('Passwords do not match');

		try {
			setLoading(() => loading);
			await Auth.forgotPasswordSubmit(email, recoveryCode, newPassword);
			onAlert('Password was reset successfully', AlertType.SUCCESS);
			navigate('/');
		} catch (error: any) {
			onAlert(COGNITO.FORGOT_PASSWORD_ERROR_MESSAGES[error.code] ?? error.message);
		} finally {
			setLoading(() => false);
		}
	}

	return (
		<>
			<Header>
				Account recovery
			</Header>
			{ !codeSent ? (
				<Form.Input
					placeholder='email'
					type='email'
					value={email}
					onChange={(_event, { value }) => setEmail(() => value)}
					width={16}
				/>
			) : (
				<>
					<Form.Input
						placeholder='code'
						type='code'
						value={recoveryCode}
						onChange={(_event, { value }) => setRecoveryCode(() => value)}
						width={16}
					/>
					<Form.Input
						placeholder='new password'
						type='password'
						value={newPassword}
						onChange={(_event, { value }) => setNewPassword(() => value)}
						width={16}
					/>
					<Form.Input
						placeholder='confirm new password'
						type='password'
						value={newPasswordConfirmation}
						onChange={(_event, { value }) => setNewPasswordConfirmation(() => value)}
						width={16}
					/>
				</>
			)}
			<Button
				primary
				onClick={codeSent ? onChangePassword : onSendRecoveryCode}
				loading={loading}
			>
				{codeSent ? 'Change Password' : 'Send Code'}
			</Button>
		</>
	);
}

export default ForgotPassword;
