const STATUS = {
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	INIT: 'INIT'
};

const PARTNER_STATUS = {
	ONBOARDING: 'onboarding',
	ACTIVE: 'active',
	INACTIVE: 'inactive'
};

const PARTNERS = {
	FEEDING_AMERICA: 'feeding_america',
	OTHER: 'other'
};

export {
	PARTNERS, PARTNER_STATUS, STATUS
}
