const NAMES: Record<string, string> = {
	ID_TOKEN: 'idToken',
	REFRESH_TOKEN: 'refreshToken',
	ACCESS_TOKEN: 'accessToken'
}

const TIMERS_REFRESH_TOKEN_DURATION: number = 1000 * 60 * 20; // twenty minutes in milliseconds

export default {
	NAMES,
	TIMERS_REFRESH_TOKEN_DURATION
}
