import { DAYS, DAYS_DICT, PARTNER_STATUS } from '../../constants';

// TODO: clean this up
class PickupDaysHelper {
	/**
	* @description sorts the days according to
	* the order in the dictionary
	*/
	static sortDays = (days: string[], dict: any) =>
		/* eslint-disable implicit-arrow-linebreak */
		days.sort((a: any, b: any) => dict[a].index - dict[b].index);

	// TODO: typing
	static options = (status: string) => DAYS.map(({ value, label }: { value: any, label: any }) => ({
		value,
		text: label,
		key: value,
		disabled: value === 'na' ? [PARTNER_STATUS.ACTIVE, PARTNER_STATUS.INACTIVE].includes(status) : false
	}));

	// TODO: typing
	static onChangeDropdown = (days: string[], onChange: Function) => {
		if (days.length > 1 && days[0] === 'na') {
			const newValues = days.filter((item: any) => item !== 'na');
			onChange(this.sortDays(newValues, DAYS_DICT));
		} else if (days.length > 0 && days[days.length - 1] === 'na') {
			onChange(['na']);
		} else {
			onChange(this.sortDays(days, DAYS_DICT));
		}
	}
}

export default PickupDaysHelper;
