import { DROPDOWNS } from '../../constants/index';

class CommunityPartnerNameHelper {
	static getStatusObj = (status: string) => {
		const options = DROPDOWNS.onboardingOptions.filter(({ value }) => value === status);
		if (options.length === 1) {
			return options[0];
		}
		return {
			value: 'N/A',
			label: 'N/A',
			color: 'grey'
		};
	};
}

export default CommunityPartnerNameHelper;
