import { SemanticCOLORS } from 'semantic-ui-react';
import { AlertType } from '../models/components/alert';

const TYPE_TO_COLOR: Record<AlertType, SemanticCOLORS> = {
	[AlertType.SUCCESS]: 'green',
	[AlertType.WARNING]: 'yellow',
	[AlertType.INFO]: 'blue',
	[AlertType.ERROR]: 'red'
}

export default {
	TYPE_TO_COLOR
}
