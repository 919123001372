import React from 'react';
import {
	Header, Grid
} from 'semantic-ui-react';

import AdditionalLink from './AdditionalLink';
import AdditionalLinksHelper from '../../lib/helpers/pages/additionalLinks';
import { AdditionalLinksProps } from '../../lib/models/pages/additionalLinks';

const AdditionalLinks = ({
	additionalLinks,
	isEditMode,
	onChange
}: AdditionalLinksProps) => {
	const onChangeLink = (index: number, value: string) => {
		AdditionalLinksHelper.handleChangeLink(additionalLinks, index, onChange, value);
	};

	const handleDeleteLink = (index: number) => {
		AdditionalLinksHelper.handleDeleteLink(index, additionalLinks, onChange);
	};

	const handleAddLink = (link: string) => {
		AdditionalLinksHelper.handleAddLink(link, additionalLinks, onChange);
	};

	return (
		<>
			<span>
				<Header className='donation-form'>Additional Links</Header>
			</span>
			<Grid.Row className='grid-row'>
				<b>
					Please add relevant links pertaining
					to this non-profit in the section below.
				</b>
			</Grid.Row>
			{additionalLinks?.map((link: string, index: number) => (
				<AdditionalLink
					additionalLink={link}
					index={index}
					isEditMode={isEditMode}
					onChangeLink={onChangeLink}
					handleAddLink={handleAddLink}
					handleDeleteLink={handleDeleteLink}
					deleteDisabled={additionalLinks.length === 1}
				/>
			))}
		</>
	)
};

export default AdditionalLinks;
