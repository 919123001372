import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import US from './lib/locale/na/us.json';
import DE from './lib/locale/eu/de.json';
import ES from './lib/locale/eu/es.json';
import IT from './lib/locale/eu/it.json';
import UK from './lib/locale/eu/uk.json';
import LOCAL_STORAGE from './lib/constants/localeStorage';

const fallbackCountry: string = 'US';
const defaultCountry: string = window.localStorage.getItem(LOCAL_STORAGE) || fallbackCountry;

i18next.use(initReactI18next).init({
	resources: {
		US,
		DE,
		ES,
		IT,
		UK
	},
	lng: defaultCountry,
	fallbackLng: fallbackCountry,
	/*
	 * react already safes from xss
	 * @see {@link https://www.i18next.com/translation-function/interpolation#unescape}
	 */
	interpolation: {
		escapeValue: false
	}
});

export default i18next;
