import { CloudFrontSettings } from './config';

const devSettings: CloudFrontSettings = {
	apiUrl: process.env.REACT_APP_API_URL!,
	region: 'us-east-1',
	authenticationFlowType: 'USER_SRP_AUTH',
	userPoolId: process.env.REACT_APP_USER_POOL_ID!,
	userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID!,
	mandatorySignIn: 'false'
}

export default devSettings;
