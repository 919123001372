export interface SiteDetails {
	[siteName: string]: {
		address: string;
		areaManager: string;
		country: string;
		name: string;
		state: string;
		type: string;
		zipcode: string;
	}
}

const SITE_DETAILS: SiteDetails = {
	'': {
		address: '',
		areaManager: '',
		country: '',
		name: '',
		state: '',
		type: '',
		zipcode: ''
	}
}

export default SITE_DETAILS;
