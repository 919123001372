export enum Region {
	EU,
	NA
}

export enum Country {
	DE = 'DE',
	ES = 'ES',
	IT = 'IT',
	UK = 'UK',
	US = 'US'
}

export const COUNTRY_REGION: Record<Country, Region> = {
	[Country.DE]: Region.EU,
	[Country.ES]: Region.EU,
	[Country.IT]: Region.EU,
	[Country.UK]: Region.EU,
	[Country.US]: Region.NA
};
