import React from 'react';

import './Footer.css';

const Footer = () => (
	<div className='home-page-footer'>
		For questions or concerns regarding Amazon food donation pick-up please contact the
		Community organization at:&nbsp;
		<a href='mailto:food_donation_inquiry@amazon.com'>
			food_donation_inquiry@amazon.com
		</a>
		<br />
		Please note, do not distribute this email to others. It should only be used by
		non-profits registered to receive food donation inventory from Amazon sites.
	</div>
)

export default Footer;
