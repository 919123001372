import { PARTNERS } from '../../constants';
import { UserInfo } from '../../models/userInfo';

class OrganizationInfoHelper {
	static getFormattedPhone = (phone: string, userInfo: UserInfo) => {
		const NUMBER_OF_DIGITS = userInfo.isEU ? 12 : 10;
		const NUMBER_OF_DASHES = userInfo.isEU ? 0 : 2;
		const DASH_INDEXES = [3, 6];
		const PHONE_MAX_LENGTH = userInfo.isEU
			? NUMBER_OF_DIGITS
			: NUMBER_OF_DIGITS + NUMBER_OF_DASHES;

		if (phone.length > PHONE_MAX_LENGTH) return phone.slice(0, PHONE_MAX_LENGTH);
		if (phone[phone.length - 1] === '-') return phone;

		const withoutDash = phone?.split('-')?.join('') ?? '';

		let formattedPhone = '';
		if (userInfo.isEU) {
			return withoutDash;
		}

		withoutDash.split('').forEach((digit, index) => {
			/* eslint-disable radix */
			if (Number.isNaN(parseInt(digit))) return;

			formattedPhone += DASH_INDEXES.includes(index) ? `-${digit}` : digit;
		});

		return formattedPhone;
	};

	// TODO: typing
	static getIsFeedingAmerica = (partnerInformation: any) => {
		if (partnerInformation === PARTNERS.FEEDING_AMERICA) {
			return 'Yes';
		}
		if (partnerInformation === PARTNERS.OTHER) {
			return 'No';
		}
		return '-';
	};

	static onPhoneChange = (e: React.SyntheticEvent, onChange: Function, userInfo: UserInfo) => {
		const phone = (e.target as HTMLInputElement).value;
		const contactPhoneFormatted = OrganizationInfoHelper.getFormattedPhone(phone, userInfo);
		onChange('contactPhone', contactPhoneFormatted);
	};

	static getValidatedZipCode = (
		zipCode: string,
		maxLength = 5,
		includesLetters = false
	) => {
		// slice to size if more than max length
		if (zipCode.length > maxLength) return zipCode.slice(0, maxLength);
		// if letters are allowed (ex: UK postalCodes) upper case zipCode
		if (includesLetters) return zipCode.toUpperCase();

		// if letters aren't allowed takes out letters from zipCode
		return zipCode.replace(/[^0-9]/g, '');
	};

	static onZipCodeChange = (e: React.SyntheticEvent, t: Function, onChange: Function) => {
		const zipCode = (e.target as HTMLInputElement).value;
		const ZIP_MAX_LENGTH = t('zipCodeLength');
		const ZIP_INCLUDES_LETTERS = t('zipCodeIncludesLetters');
		const validatedZipCode = OrganizationInfoHelper.getValidatedZipCode(
			zipCode,
			ZIP_MAX_LENGTH,
			ZIP_INCLUDES_LETTERS
		);
		onChange('address.zipCode', validatedZipCode);
	};
}

export default OrganizationInfoHelper;
