import React, { useContext } from 'react';

import { COGNITO } from '../../lib/constants/index';
import UserInfoContext from '../../contexts/userInfo';

import Navbar from '../Navbar/Navbar';

interface LayoutProps {
	children: JSX.Element | JSX.Element[];
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const userInfo = useContext(UserInfoContext);

	if (userInfo.loginStatus === COGNITO.STATUSES.UNKNOWN) {
		return (
			<div className='authenticating-text'>
				Authenticating...
			</div>
		)
	}
	return	(
		<>
			<Navbar />
			{ children }
		</>
	)
}

export default Layout;
