import React, { useContext, useEffect, useState } from 'react';
import {
	Button, Divider, Segment, Header as TextHeader, Modal
} from 'semantic-ui-react';

import { COLORS, INITIAL_STATES } from '../../lib/constants';
import EditProfileHelper from '../../lib/helpers/pages/editProfile';
import { EditProfileProps } from '../../lib/models/pages/editProfile';
import AppContext, { AppContextObject } from '../../contexts/app';

import LoadingState from '../../Components/LoadingState/LoadingState';
import CommunityPartnerName from '../../Components/CommunityPartnerName/CommunityPartnerName';
import SiteSelection from '../../Components/SiteSelection/SiteSelection';

import { AlertType } from '../../lib/models/components/alert';
import OrganizationInfo from './OrganizationInfo';
import UserInfoContext from '../../contexts/userInfo';
import { UserInfo } from '../../lib/models/userInfo';
import TaxDocuments from './TaxDocuments';
import AdditionalLinks from './AdditionalLinks';
import OtherDocuments from './OtherDocuments';
import { PickupSiteDetails } from '../../lib/models/communityPartner';

const EditProfile = ({
	data, isEditMode, setEditState, getFreshData
}: EditProfileProps) => {
	const app: AppContextObject = useContext(AppContext);
	const userInfo: UserInfo = useContext(UserInfoContext);
	const [editedData, setEditedData] = useState(data);
	const [alert, setAlert] = useState(INITIAL_STATES.ALERT_MESSAGE);
	const [isLoading, setIsLoading] = useState(false);

	const {
		additionalLinks, address, categories,
		contactEmail, contactName, contactPhone,
		documents, foodbankName, id,
		partnerInformation, sites, status,
		taxDocuments, taxId, websiteUrl
	} = editedData;

	useEffect(() => {
		setEditedData(data);
	}, [data]);

	const onSaveClick = async () => {
		setIsLoading(true);
		try {
			await app.communityTrackerApi.editPartnerProfile(editedData);
			app.handleAlert({
				header: 'Success',
				content: 'Partner profile updated successfully',
				type: AlertType.SUCCESS
			});
		} catch (error: any) {
			setEditedData(data);
			app.handleAlert({
				header: 'Error updating partner profile',
				content: error?.message,
				type: AlertType.ERROR
			});
		} finally {
			setEditState(false);
			setIsLoading(false);
		}
	}

	const onCancelClick = () => {
		setEditedData(data);
		setEditState(false);
	}

	const onChangeAdditionalLinksLocal = (linksData: string[]) => {
		setEditedData({ ...editedData, additionalLinks: linksData });
	}

	const onChangeSites = (sitesDropdownData: PickupSiteDetails) => {
		EditProfileHelper.onChangeSites(
			sitesDropdownData, editedData, setEditedData
		);
	}

	const onChange = (attr: string, value: string) => {
		EditProfileHelper.onChangeProfileData(
			attr, value, editedData, setEditedData
		);
	}

	const deleteSite = (selectedSiteName: string) => {
		EditProfileHelper.deleteSite(
			editedData, selectedSiteName, setEditedData, sites
		);
	}

	return (
		<div>
			<div className='form-container'>
				<Modal
					onClose={() => setAlert(INITIAL_STATES.ALERT_MESSAGE)}
					open={alert.type !== INITIAL_STATES.ALERT_MESSAGE.type}
					closeIcon
					size='tiny'
				>
					<Modal.Content style={COLORS.backgroundColor}>
						<Modal.Description style={COLORS.color}>
							{alert.type}
							:&nbsp;
							{alert.content}
						</Modal.Description>
					</Modal.Content>
				</Modal>
				{isLoading && <LoadingState />}
				<Segment>
					{isEditMode ? (
						<div className='form-action-buttons'>
							<Button color='red' onClick={() => onCancelClick()}>
								Cancel
							</Button>
							<Button
								color='green'
								onClick={onSaveClick}
							>
								&nbsp; Save &nbsp;
							</Button>
						</div>
					) : (
						<div className='form-action-buttons'>
							<Button color='blue' onClick={() => setEditState(true)}>
								&nbsp;Edit&nbsp;
							</Button>
						</div>
					)}
					<TextHeader className='foodbank-name-header'>
						Foodbank Details
					</TextHeader>
					<CommunityPartnerName
						communityPartnerName={foodbankName}
						communityPartnerStatus={status}
						isEditMode={isEditMode}
						onChange={onChange}
					/>
					<Divider />
					<SiteSelection
						country={userInfo.country}
						deleteSite={deleteSite}
						isEditMode={isEditMode}
						onChange={onChangeSites}
						sites={sites}
						status={status}
					/>
					<Divider />
					<OrganizationInfo
						address={address}
						categories={categories}
						contactEmail={contactEmail}
						contactName={contactName}
						contactPhone={contactPhone}
						isEditMode={isEditMode}
						onChange={onChange}
						partnerInformation={partnerInformation}
						taxId={taxId}
						websiteUrl={websiteUrl}
					/>
					<Divider />
					<br />
					<AdditionalLinks
						additionalLinks={additionalLinks}
						isEditMode={isEditMode}
						onChange={onChangeAdditionalLinksLocal}
					/>
					<Divider />
					<TaxDocuments
						taxDocuments={taxDocuments}
						partnerName={foodbankName}
					/>
					<Divider />
					<br />
					<OtherDocuments
						partnerId={id}
						documents={documents}
						isEditMode={isEditMode}
						getFreshData={getFreshData}
						onChange={onChange}
					/>
				</Segment>
			</div>
		</div>
	);
}

export default EditProfile;
