import React, { useEffect, useState, useContext } from 'react';
import {
	Table,
	Popup,
	Icon
} from 'semantic-ui-react';
import {
	SiteSelectionProps
	// SiteDaysMapping
} from '../../lib/models/components/siteSelection';

import AppContext, { AppContextObject } from '../../contexts/app';
import SiteSearchDropdown from '../SiteSearchDropDown/SiteSearchDropDown';
import PickupDays from './PickupDays';
import sortDays from '../../lib/helpers/days';
import SiteSelectionHelper from '../../lib/helpers/components/siteSelection';
import { AlertType } from '../../lib/models/components/alert';
import SITE_DETAILS from '../../lib/constants/initialStates/siteDetails';
import { SiteOption } from '../../lib/models/communityPartner';

const SiteSelection = ({
	country,
	deleteSite,
	isEditMode,
	onChange,
	sites,
	status,
	celledTabled
}: SiteSelectionProps) => {
	const app: AppContextObject = useContext(AppContext);
	/* eslint-disable @typescript-eslint/no-unused-vars */
	const [sitesDetails, setSiteDetails] = useState(SITE_DETAILS);

	useEffect(() => {
		const siteNames = SiteSelectionHelper.getSiteNames(sites);

		if (siteNames.length > 0) {
			SiteSelectionHelper.getSitesDetails(AlertType, app, setSiteDetails, siteNames);
		}
	}, [app, app.communityTrackerApi, sites]);

	const onSiteSelection = (values: any, options: SiteOption[]) => {
		SiteSelectionHelper.onSiteSelection(onChange, options, sites, values)
	};

	const onPickupDayChange = (siteName: string, values: string[]) => {
		SiteSelectionHelper.onPickupDayChange(onChange, siteName, sites, values);
	};

	return (
		<>
			<Table
				basic={celledTabled ? undefined : 'very'}
				celled={celledTabled}
			>
				<Table.Header>
					<Table.Row>
						{isEditMode ? <Table.HeaderCell>Delete</Table.HeaderCell> : null}
						<Table.HeaderCell>Site</Table.HeaderCell>
						<Table.HeaderCell>Site Address</Table.HeaderCell>
						<Table.HeaderCell>Type</Table.HeaderCell>
						<Table.HeaderCell>Pickup Days</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{sites.map(({ name, days }) => (
						<Table.Row key={name}>
							{isEditMode ? (
								<Table.Cell>
									<Popup
										trigger={
											<Icon color='red' name='minus circle' size='large' className='delete-icon' onClick={() => deleteSite(name)} />
										}
										content='Delete this site'
										basic
									/>
								</Table.Cell>
							) : null}
							<Table.Cell>
								{name}
							</Table.Cell>
							<Table.Cell>
								{sitesDetails?.[name]?.address}
							</Table.Cell>
							<Table.Cell>
								{sitesDetails?.[name]?.type?.toUpperCase()}
							</Table.Cell>
							<Table.Cell>
								{isEditMode
									? (
										<PickupDays
											siteName={name}
											values={days}
											onChange={(values: any) => onPickupDayChange(name, values)}
											partnerStatus={status}
										/>
									) : (sortDays(days)[1].join(', ') || 'N/A')}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			{sites.length === 0 && <div style={{ textAlign: 'center' }}>No sites</div>}
			{isEditMode && (
				<Table basic='very'>
					<Table.Body>
						<Table.Row>
							<Table.Cell>Add new</Table.Cell>
							<Table.Cell>
								<SiteSearchDropdown
									country={country}
									sites={sites}
									onChange={onSiteSelection}
								/>
							</Table.Cell>
							<Table.Cell />
						</Table.Row>
					</Table.Body>
				</Table>
			)}
		</>
	);
};

export default SiteSelection;
