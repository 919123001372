import React from 'react';

const Description = () => (
	<div className='home-community-description'>
		Thank you for being an Amazon food donation partner! At Amazon, we’ve
		developed programs to optimize inventory management and to repair,
		repurpose and donate usable items.
		<br />
		<br />
		Amazon is committed to reducing our food waste by 50% across our U.S. and
		Europe operations by 2030. To learn more about our efforts to re-direct food
		waste please visit &nbsp;
		<a
			target='_blank'
			href='https://sustainability.aboutamazon.com/environment/avoiding-and-managing-waste'
			rel='noreferrer'
		>
			Amazon&apos;s Sustainability site
		</a>
		.
	</div>
)

export default Description;
