type challenge = 'NEW_PASSWORD_REQUIRED';
const CHALLENGES: Record<challenge, string> = {
	NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED'
}

const FORGOT_PASSWORD_ERROR_MESSAGES: Record<string, string> = {
	InvalidParameterException: 'password must have a minimum length of 8 and contain special character, uppercase letter, lowercase letter.',
	CodeMismatchException: 'Invalid verification code provided, please try again.'
}

const CODE_DELIVERY_DETAILS: string = 'CodeDeliveryDetails';

type status = 'LOGGED_IN' | 'LOGGED_OUT' | 'UNKNOWN';
const STATUSES: Record<status, string> = {
	LOGGED_IN: 'LoggedIn',
	LOGGED_OUT: 'LoggedOut',
	UNKNOWN: 'Unknown'
}

export default {
	CHALLENGES,
	CODE_DELIVERY_DETAILS,
	STATUSES,
	FORGOT_PASSWORD_ERROR_MESSAGES
}
