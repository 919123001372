import React from 'react';
import { Menu } from 'semantic-ui-react'

interface NavbarLgProps {
	renderLinks: () => JSX.Element;
}

const NavbarLg: React.FC<NavbarLgProps> = ({ renderLinks }) => (
	<div className='navbar-wrapper'>
		<Menu inverted>
			{renderLinks()}
		</Menu>
	</div>
)

export default NavbarLg;
