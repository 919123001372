import ALERT from './alert';
import COOKIES from './cookies';
import COGNITO from './cognito';
import DONATIONS from './donations';
import INITIAL_STATES from './initialStates';
import LOCALE_STORAGE from './localeStorage';
import { optionsFeedingAmerica, onboardingOptions } from './dropDowns';
import { DAYS, DAYS_DICT } from './days';
import { PARTNERS, PARTNER_STATUS, STATUS } from './options';
import { backgroundColor, color } from './colors';
import durationMs from './session';
import WEIGHTS from './weights';

const COLORS = {
	backgroundColor,
	color
};

const DROPDOWNS = {
	optionsFeedingAmerica,
	onboardingOptions
};

const SESSION = {
	durationMs
}

export {
	ALERT,
	COGNITO,
	COOKIES,
	COLORS,
	DAYS,
	DAYS_DICT,
	DONATIONS,
	DROPDOWNS,
	INITIAL_STATES,
	PARTNERS,
	PARTNER_STATUS,
	STATUS,
	LOCALE_STORAGE,
	SESSION,
	WEIGHTS
};
