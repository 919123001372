import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import {
	Dropdown, Grid, Form, DropdownItemProps
} from 'semantic-ui-react';
import { SearchFiltersProps } from '../../lib/models/pages/viewDonations';
import ViewDonationsHelper from '../../lib/helpers/pages/viewDonations';
import DateTimeHelper from '../../lib/helpers/dateTime';
import AppContext, { AppContextObject } from '../../contexts/app';
import { UserInfo } from '../../lib/models/userInfo';
import UserInfoContext from '../../contexts/userInfo';

// TODO: add donation view type to cookies to keep view preference
const SearchFilters: React.FC<SearchFiltersProps> = ({
	dateTimestampValue, categoryType, donationType, sitesValues,
	onChangeDonationType, onChangeCategoryType, onChangeDate, onChangeSites
}) => {
	const { t } = useTranslation();
	const app: AppContextObject = useContext(AppContext);
	const userInfo: UserInfo = useContext(UserInfoContext);
	const categoryTypeOptions: DropdownItemProps[] = ViewDonationsHelper.getDropdownOptions((t('categoryTypes', { returnObjects: true }) as string[]));
	const donationTypeOptions: DropdownItemProps[] = ViewDonationsHelper.getDonationTypeOptions();
	const endOfTodayTime: number = DateTimeHelper.getEndOfTodayEpoch();
	const startOfYearTime: number = DateTimeHelper.getFirstDayOfYearEpoch();
	const [siteOptions, setSiteOptions] = useState<DropdownItemProps[]>([]);

	useEffect(() => {
		const getPickupSitesFromPartner = async () => {
			const { data } = await app.communityTrackerApi.getPartnerProfile(userInfo.foodbankId);
			const sites: string[] = data.sites.map((site) => site.name);
			setSiteOptions(() => ViewDonationsHelper.getDropdownOptions(sites));
		}
		getPickupSitesFromPartner();
	}, [app.communityTrackerApi, userInfo.foodbankId]);

	return (
		<div className='filter-container'>
			<Grid centered>
				<Grid.Column width={3}>
					<Dropdown
						className='ui'
						fluid
						selection
						options={categoryTypeOptions}
						placeholder='Category'
						value={categoryType}
						onChange={(_e, { value }) => onChangeCategoryType(value)}
					/>
				</Grid.Column>
				<Grid.Column width={3}>
					<Dropdown
						fluid
						multiple
						selection
						options={siteOptions}
						placeholder='Sites filter'
						value={sitesValues}
						onChange={(_e, { value }) => onChangeSites(value)}
					/>
				</Grid.Column>
				<Grid.Column width={3}>
					<Dropdown
						className='ui'
						fluid
						selection
						options={donationTypeOptions}
						placeholder='Donation Type'
						value={donationType}
						onChange={(_e, { value }) => {
							onChangeDate(value === 'daily' ? endOfTodayTime : startOfYearTime);
							onChangeDonationType(value);
						}}
					/>
				</Grid.Column>
				<Grid.Column width={3}>
					<Form>
						<DatePicker
							dateFormat={donationType === 'daily' ? t('dateFormat') : 'yyyy'}
							showYearPicker={donationType === 'monthly'}
							placeholderText='Select Date'
							selected={new Date(dateTimestampValue)}
							onChange={(value: Date) => onChangeDate(new Date(value).getTime())}
						/>
					</Form>
				</Grid.Column>
			</Grid>
		</div>
	)
}

export default SearchFilters;
