import React from 'react';
import {
	Button, Icon, Segment, Table
} from 'semantic-ui-react';

import { DonationEntriesListProps, DonationEntry } from '../../lib/models/pages/reportDonations';
import DateTimeHelper from '../../lib/helpers/dateTime';

const DonationEntriesList: React.FC<DonationEntriesListProps> = (props) => {
	const {
		donationEntries,
		onDeleteEntry,
		onSubmitReport
	} = props;

	return (
		<Segment>
			<div className='donation-entries-list'>
				{donationEntries.length === 0
					? (
						<div className='donation-entries-list-empty'>
							no donation entries added
							<br />
							add donations to report by filling the form bellow
						</div>
					) : (
						<Table celled>
							<Table.Body>
								{donationEntries.map((entry: DonationEntry, index: number) => (
									<Table.Row key={entry.donatedAt}>
										<Table.Cell>
											{entry.site}
										</Table.Cell>
										<Table.Cell>
											{entry.type}
										</Table.Cell>
										<Table.Cell>
											{entry.type === 'monthly'
												? DateTimeHelper.epochToMonthYear(entry.donatedAt!)
												: DateTimeHelper.epochToDateString(entry.donatedAt!)}
										</Table.Cell>
										<Table.Cell>
											{entry.category}
										</Table.Cell>
										<Table.Cell>
											{entry.weight}
										</Table.Cell>
										<Table.Cell width={1}>
											<Button
												icon
												onClick={() => onDeleteEntry(index)}
											>
												<Icon name='trash alternate outline' />
											</Button>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					)}
			</div>
			{donationEntries.length !== 0 && (
				<Button
					color='teal'
					onClick={() => onSubmitReport()}
				>
					Submit donation report
				</Button>
			)}
		</Segment>
	)
}

export default DonationEntriesList;
