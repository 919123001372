import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Image, Sidebar } from 'semantic-ui-react'

const HamIcon = (): JSX.Element => <i className='big bars icon inverted' />;
const CloseIcon = (): JSX.Element => <i className='big close red icon' />;
const Overlay = (): JSX.Element => <div className='navbar-mb-overlay' />;

interface NavbarMbProps {
	renderLinks: () => JSX.Element;
}

const NavbarMb: React.FC<NavbarMbProps> = ({ renderLinks }) => {
	const [visible, setVisible] = useState(false);
	const [icon, setIcon] = useState(HamIcon);

	const hideSidebar = () => {
		setIcon(HamIcon);
		setVisible(false);
	}
	const showSidebar = () => {
		setIcon(CloseIcon);
		setVisible(true);
	}

	const toggleSidebar = () => (visible ? hideSidebar() : showSidebar())

	return (
		<>
			{visible && <Overlay />}
			<Menu
				inverted
				size='tiny'
				borderless
				attached
			>
				<Menu.Item
					as={Link}
					to='/'
				>
					<Image
						src='/GSF-Community-logo.jpg'
						className='logo'
					/>
				</Menu.Item>
				<Menu.Menu position='right'>
					<Menu.Item onClick={toggleSidebar}>
						{icon}
					</Menu.Item>
				</Menu.Menu>
			</Menu>
			<Sidebar
				as={Menu}
				animation='overlay'
				icon='labeled'
				inverted
				vertical
				visible={visible}
				width='thin'
			>
				{renderLinks()}
			</Sidebar>
		</>
	)
}

export default NavbarMb;
