import { CommunityPartner } from '../../models/communityPartner';

const COMMUNITY_PARTNER: CommunityPartner = {
	additionalLinks: [],
	address: {
		line1: '',
		line2: '',
		city: '',
		state: '',
		zipCode: ''
	},
	categories: [],
	contactEmail: '',
	contactName: '',
	contactPhone: '',
	country: '',
	createdAt: 0,
	createdBy: '',
	documents: [],
	foodbankName: '',
	id: '',
	modifiedAt: 0,
	modifiedBy: '',
	partnerInformation: '',
	sites: [],
	status: '',
	taxId: '',
	taxDocuments: [],
	websiteUrl: ''
};

export default COMMUNITY_PARTNER;
