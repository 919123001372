import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { DROPDOWNS } from '../../lib/constants';
import { CommunityPartnerStatusProps } from '../../lib/models/components/communityPartnerStatus';

const CommunityPartnerStatus = ({
	communityPartnerStatus,
	classNames
}: CommunityPartnerStatusProps) => {
	const dropdownOptions = DROPDOWNS.onboardingOptions.map(({ color, value, label }) => ({
		value,
		key: value,
		text: label,
		label: { color, empty: true, circular: true }
	}));

	return (
		<Dropdown
			className={classNames}
			value={communityPartnerStatus}
			placeholder='Current status'
			options={dropdownOptions}
			inline
		/>
	);
};

export default CommunityPartnerStatus;
