import { AppContextObject } from '../../../contexts/app';
import { AlertType } from '../../models/components/alert';

class OtherDocumentsHelper {
	static download = async (
		app: AppContextObject, e: React.SyntheticEvent,
		s3Key: string, type: string, filename = ''
	) => {
		e.preventDefault();
		const { file, contentType } = await app.communityTrackerApi.downloadDocument(s3Key, type);

		const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(file, 'base64')]));
		const link = document.createElement('a');
		link.href = blobUrl;

		let extension = '';
		if (filename.indexOf('.') === -1) {
			extension = `.${contentType.split('/')[1]}`;
		}

		link.setAttribute('download', `${filename}${extension}`);
		document.body.appendChild(link);
		link.click();
	};

	static getTaxYears = () => {
		const currentYear = new Date().getFullYear();
		return [currentYear, currentYear - 1, currentYear - 2]
			.map((year) => ({ key: year, text: year, value: year }));
	}

	static getBase64 = (file: File) => new Promise<string|ArrayBuffer|null>((resolve, reject) => {
		const reader: FileReader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = (error) => {
			reject(error);
		};
	});

	static onFileChanged = (event: React.SyntheticEvent, setUpload: Function) => {
		const target = event.target as HTMLInputElement;
		setUpload(() => !!target.files?.[0]);
	}

	static uploadForm = async (
		selectedFileRef: React.MutableRefObject<any>,
		app: AppContextObject,
		partnerId: string,
		getFreshData: Function,
		setIsLoading: Function
	) => {
		if (selectedFileRef.current.files.length === 0) {
			app.handleAlert({
				header: 'Error uploading file',
				content: 'Please select a file',
				type: AlertType.ERROR
			});
			return;
		}
		const selectedFile = selectedFileRef.current.files[0];
		setIsLoading(() => true);
		try {
			const base64Data = await this.getBase64(selectedFile);
			await app.communityTrackerApi.uploadDocument(partnerId, base64Data, selectedFile);
			getFreshData();
		} catch (error: any) {
			app.handleAlert({
				header: 'Error uploading other document',
				content: error?.message,
				type: AlertType.ERROR
			});
		} finally {
			setIsLoading(() => false);
		}
	}
}

export default OtherDocumentsHelper;
