import React, {
	MutableRefObject, useContext, useRef, useState
} from 'react';
import {
	Button, Grid, Header, Icon, Popup, Table
} from 'semantic-ui-react';
import './Profile.css';

import AppContext, { AppContextObject } from '../../contexts/app';
import OtherDocumentsProps from '../../lib/models/pages/otherDocuments';
import OtherDocumentsHelper from '../../lib/helpers/pages/otherDocuments';

import LoadingState from '../../Components/LoadingState/LoadingState';

const OtherDocuments = ({
	documents, getFreshData, isEditMode, onChange, partnerId
}: OtherDocumentsProps) => {
	const sortedDocuments = documents.sort((a: any, b: any) => b.createdAt - a.createdAt);
	const app: AppContextObject = useContext(AppContext);

	const selectedFileRef:MutableRefObject<HTMLInputElement|null> = useRef(null);
	const [upload, setUpload] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const onDownload = (e: React.SyntheticEvent, s3Key: string, type: string, filename = '') => {
		OtherDocumentsHelper.download(app, e, s3Key, type, filename);
	};

	const onFileChanged = (event: React.SyntheticEvent) => {
		OtherDocumentsHelper.onFileChanged(event, setUpload);
	}

	const uploadForm = () => {
		OtherDocumentsHelper.uploadForm(
			selectedFileRef,
			app,
			partnerId,
			getFreshData,
			setIsLoading
		);
		// NOTE: optional property access e.g. selectedFileRef?.current is
		// not possible for an assignment. Don't even try it. Or else.
		if (selectedFileRef.current !== undefined && selectedFileRef.current !== null) {
			selectedFileRef.current.value = '';
		}
	}

	const onDeleteForm = (s3Key: string) => {
		const filteredDocument = documents.filter((item) => item.s3Key !== s3Key);
		onChange('documents', filteredDocument);
	};

	return (
		<>
			{ isLoading ? (
				<LoadingState />
			) : (
				<>
					<span>
						<Header className='donation-form'>Other Documents</Header>
					</span>
					{isEditMode ? (
						<Grid className='donation-upload'>
							<Grid.Column width={4}>
								<input
									className='form-upload-file'
									type='file'
									name='donation-form-upload'
									onChange={onFileChanged}
									ref={selectedFileRef}
								/>
							</Grid.Column>
							<Grid.Column width={8}>
								<Button
									color='green'
									onClick={() => uploadForm()}
									disabled={!upload}
								>
									Upload
								</Button>
							</Grid.Column>
						</Grid>
					) : null}
					<br />
					{sortedDocuments.length > 0 ? (
						<Table basic='very'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Type</Table.HeaderCell>
									<Table.HeaderCell>File Name</Table.HeaderCell>
									<Table.HeaderCell>Upload Date</Table.HeaderCell>
									<Table.HeaderCell>Uploaded By</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{sortedDocuments.map(({
									s3Key, createdAt, createdBy, name, type
								}) => (
									<Table.Row key={s3Key}>
										{isEditMode ? (
											<Table.Cell>
												<Popup
													trigger={
														<Icon color='red' name='minus circle' size='large' className='delete-icon' onClick={() => onDeleteForm(s3Key)} />
													}
													content='Delete this document'
													basic
												/>
											</Table.Cell>
										) : null}
										<Table.Cell>{type && `${type[0].toUpperCase()}${type.substring(1)}`}</Table.Cell>
										<Table.Cell>
											<a
												href='/'
												onClick={(e) => onDownload(e, s3Key, type, name)}
												rel='noreferrer'
											>
												{name}
											</a>
										</Table.Cell>
										<Table.Cell>{createdAt}</Table.Cell>
										<Table.Cell>
											{createdBy}
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					) : null}
				</>
			)}
		</>
	);
};

export default OtherDocuments;
