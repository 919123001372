import React, { useState } from 'react';
import {
	Button, Form, Header as TextHeader, Message
} from 'semantic-ui-react';

interface CompletePasswordProps {
	readonly loading: boolean;
	readonly onSubmit: Function;
	readonly onAlert: Function;
}

const CompletePassword: React.FC<CompletePasswordProps> = ({ onSubmit, onAlert, loading }) => {
	const [password1, setPassword1] = useState('');
	const [password2, setPassword2] = useState('');

	const onClickSubmit = () => {
		if (!password1) return onAlert('Please enter a valid password');
		if (password1 !== password2) return onAlert('Passwords do not match');

		return onSubmit(password1);
	}

	return (
		<>
			<TextHeader>
				Create a new password
			</TextHeader>
			<Message color='green' className='password-message'>
				<Message.Header>The new password should contain:</Message.Header>
				<Message.List>
					<Message.Item>At least 1 number</Message.Item>
					<Message.Item>At least one uppercase letter</Message.Item>
					<Message.Item>At least one lowercase letter</Message.Item>
					<Message.Item>
						At least one of the following characters:
						<br />
						{/* eslint-disable-next-line max-len */}
						^ &#36; &#42; &#46; &#91; &#93; &#123; &#125; &#40; &#41; &#63; - &quot; ! @ # % &amp; / \ , &apos; &lt; &gt; : ; | _ ~ ` + =
					</Message.Item>
				</Message.List>
			</Message>
			<Form.Input
				placeholder='New Password'
				type='password'
				value={password1}
				onChange={(_: any, { value }: {value: any}) => setPassword1(value)}
				width={16}
			/>
			<Form.Input
				placeholder='Confirm New password'
				type='password'
				value={password2}
				onChange={(_, { value }) => setPassword2(value)}
				width={16}
			/>
			<Button onClick={onClickSubmit} loading={loading}>
				Change Password
			</Button>
		</>
	);
}

export default CompletePassword;
