import React, { useState, useCallback } from 'react';
import {
	Button, Form, Icon, Input
} from 'semantic-ui-react';

interface SignInProps {
	readonly loading: boolean;
	readonly onSubmit: Function;
	readonly onAlert: Function;
}

const SignIn: React.FC<SignInProps> = ({ onSubmit, onAlert, loading }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const onSignInClick = useCallback(() => {
		if (email && password) return onSubmit(email, password);
		return onAlert('Please enter email and password');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email, password]);

	const onShowPasswordChange = () => setShowPassword((prev) => !prev);

	return (
		<>
			<Form.Input
				placeholder='email'
				type='email'
				value={email}
				onChange={(_event, { value }) => setEmail(() => value)}
				width={16}
			/>
			<Form.Input>
				<Input
					placeholder='password'
					type={showPassword ? 'text' : 'password'}
					value={password}
					onChange={(_event, { value }) => setPassword(() => value)}
					width={16}
				/>
				<Button
					basic
					icon
					type='button'
					onClick={onShowPasswordChange}
				>
					<Icon name={showPassword ? 'eye slash' : 'eye'} />
				</Button>
			</Form.Input>
			<div className='forgot-password'>
				<a href='/password-recovery'>
					forgot password ?
				</a>
			</div>
			<Button
				onClick={onSignInClick}
				color='blue'
				type='submit'
				loading={loading}
			>
				Sign In
			</Button>
		</>
	);
};

export default SignIn;
