import { AppContextObject } from '../../../contexts/app';

class TaxDocumentsHelper {
	static download = async (e: React.SyntheticEvent, app: AppContextObject, s3Key: string, filename = '') => {
		e.preventDefault();
		const { file, contentType } = await app.communityTrackerApi.downloadTaxDocument(s3Key);

		const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(file, 'base64')]));
		const link = document.createElement('a');
		link.href = blobUrl;

		let extension = '';
		if (filename.indexOf('.') === -1) {
			extension = `.${contentType.split('/')[1]}`;
		}

		link.setAttribute('download', `${filename}${extension}`);
		document.body.appendChild(link);
		link.click();
	}

	static getFormattedDate = (timestamp: number) => {
		if (!timestamp) {
			return 'N/A';
		}
		const date = new Date(timestamp);
		let timezone = date.toTimeString().match(/\(.+\)/g)![0] || '';
		timezone = timezone.match(/[A-Z]/g)!.join('');
		timezone = (timezone && `(${timezone})`) || '';
		return `${date.toLocaleString()} ${timezone}`;
	}
}

export default TaxDocumentsHelper;
