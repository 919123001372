export const ALCOHOL: string = 'alcohol';
export const AMBIENT_CONSUMABLES: string = 'ambient consumables';
export const BAKED_GOODS: string = 'baked goods';
export const DAIRY: string = 'dairy';
export const EXPIRED_FOOD: string = 'expired food';
export const FOOD: string = 'food';
export const FRESH_PRODUCE: string = 'fresh produce';
export const FROZEN_CONSUMABLES: string = 'frozen consumables';
export const HEALTH_PERSONAL_CARE_AND_BEAUTY: string = 'health personal care and beauty';
export const HOUSEHOLD_ITEMS: string = 'household items';
export const NON_FOOD: string = 'non-food';
export const OTHER_CHILLED_CONSUMABLES: string = 'other chilled consumables';
export const PET_AND_FOOD_SUPPLIES: string = 'pet food and supplies';
export const RAW_MEAT_POULTRY_AND_FISH: string = 'raw meat, poultry and fish';

export const CATEGORY_OPTION_TO_DONATION_OPTION: Record<string, string> = {
	[ALCOHOL]: ALCOHOL,
	[AMBIENT_CONSUMABLES]: FOOD,
	[BAKED_GOODS]: FOOD,
	[DAIRY]: FOOD,
	[EXPIRED_FOOD]: FOOD,
	[FOOD]: FOOD,
	[FRESH_PRODUCE]: FOOD,
	[FROZEN_CONSUMABLES]: FOOD,
	[HEALTH_PERSONAL_CARE_AND_BEAUTY]: NON_FOOD,
	[HOUSEHOLD_ITEMS]: NON_FOOD,
	[NON_FOOD]: NON_FOOD,
	[OTHER_CHILLED_CONSUMABLES]: FOOD,
	[PET_AND_FOOD_SUPPLIES]: NON_FOOD,
	[RAW_MEAT_POULTRY_AND_FISH]: FOOD
}

export const EU_OPTIONS = [
	ALCOHOL,
	AMBIENT_CONSUMABLES,
	BAKED_GOODS,
	DAIRY,
	EXPIRED_FOOD,
	FRESH_PRODUCE,
	FROZEN_CONSUMABLES,
	HEALTH_PERSONAL_CARE_AND_BEAUTY,
	HOUSEHOLD_ITEMS,
	OTHER_CHILLED_CONSUMABLES,
	PET_AND_FOOD_SUPPLIES,
	RAW_MEAT_POULTRY_AND_FISH
];
export const NA_OPTIONS = [ALCOHOL, FOOD];
