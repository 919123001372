import React, {
	useContext, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';

import AppContext, { AppContextObject } from '../../contexts/app';
import UserInfoContext from '../../contexts/userInfo';
import { AlertType } from '../../lib/models/components/alert';
import { UserInfo } from '../../lib/models/userInfo';
import { FOOD } from '../../lib/constants/categories/categories';
import { NUM_DAYS_PER_WEEK, ONE_DAY_IN_MILLISECONDS } from '../../lib/constants/dateTime';
import ViewDonationsHelper from '../../lib/helpers/pages/viewDonations';
import { DonationData, GetReportDonationsParams } from '../../lib/models/pages/viewDonations';
import { GetDailyDonationParams, GetDonationsParams, GetMonthlyDonationParams } from '../../lib/helpers/pages/viewDonationsQuery';

import DateTimeHelper from '../../lib/helpers/dateTime';

import LoadingState from '../../Components/LoadingState/LoadingState';
import SearchFilters from './SearchFilters';
import WeeklyView from './WeeklyView';
import MonthlyView from './MonthlyView';

import './ViewDonations.css';

const ViewDonations = () => {
	const userInfo: UserInfo = useContext(UserInfoContext);
	const app: AppContextObject = useContext(AppContext);
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [donationReport, setDonationReport] = useState<DonationData[]>([]);
	const [categoryType, setCategoryType] = useState(FOOD);
	const [donationType, setDonationType] = useState<'daily' | 'monthly'>('daily');
	const [timestampValue, setTimestampValue] = useState<number>(DateTimeHelper.getEndOfTodayEpoch());
	const [sitesValues, setSitesValues] = useState<string[]>([]);

	const sevenDaysBefore: number = useMemo(() => (
		timestampValue - NUM_DAYS_PER_WEEK * ONE_DAY_IN_MILLISECONDS
	), [timestampValue]);

	const sevenDaysAfter: number = useMemo(() => (
		timestampValue + NUM_DAYS_PER_WEEK * ONE_DAY_IN_MILLISECONDS
	), [timestampValue]);

	const getDonations = async (queryParams: GetReportDonationsParams) => {
		try {
			setIsLoading(() => true);
			const { data } = await app.communityTrackerApi.getReportDonations(queryParams);
			setDonationReport(() => data);
		} catch (error: any) {
			app.handleAlert({
				type: AlertType.ERROR,
				header: 'Error getting donations',
				content: error?.message
			})
		} finally {
			setIsLoading(() => false);
		}
	}

	useEffect(() => {
		let query: GetDonationsParams;
		if (!timestampValue) return;

		if (donationType === 'daily') {
			query = new GetDailyDonationParams(timestampValue, sitesValues, categoryType, donationType);
		} else {
			query = new GetMonthlyDonationParams(timestampValue, sitesValues, categoryType, donationType);
		}

		getDonations(query.getParams());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timestampValue, sitesValues, categoryType, donationType, userInfo.foodbankId]);

	const onPrevClick = () => {
		setDonationReport([]);
		setTimestampValue(sevenDaysBefore);
	}

	const onNextClick = () => {
		setDonationReport([]);
		setTimestampValue(sevenDaysAfter);
	}

	return (
		<>
			{ isLoading ? (
				<LoadingState />
			) : (
				<div className='donation-volume-container'>
					<SearchFilters
						dateTimestampValue={timestampValue}
						categoryType={categoryType}
						donationType={donationType}
						sitesValues={sitesValues}
						onChangeDate={setTimestampValue}
						onChangeCategoryType={setCategoryType}
						onChangeDonationType={setDonationType}
						onChangeSites={setSitesValues}
					/>
					<div className='donation-random-text'>
						{`Reported numbers are in (${t('weightUnit')}) of ${categoryType} donations`}
					</div>
					{ donationType === 'daily' ? (
						<WeeklyView
							onPrevClick={onPrevClick}
							onNextClick={onNextClick}
							data={donationReport}
							startingTimestamp={ViewDonationsHelper.getStartingTimestamp(timestampValue)}
							endingTimestamp={timestampValue}
						/>
					) : (
						<MonthlyView
							data={donationReport}
							startingTimestamp={DateTimeHelper.getFirstDayOfYearEpoch(timestampValue)}
						/>
					)}
				</div>
			)}
		</>
	)
}

export default ViewDonations;
