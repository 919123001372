import countriesStatesCities from 'countries-states-cities';

interface CountryOption {
	key: string,
	text: string,
	value: string
}

class CountryHelper {
	static getStatesByCountry = (countryName: string) => {
		let options: CountryOption[] = [];
		const countries = countriesStatesCities.getAllCountries();
		const country = countries.find((c) => c.name === countryName);
		if (country) {
			const states = countriesStatesCities.getStatesOfCountry(country.id);
			options = states.map((state) => ({
				key: state.name,
				text: state.name,
				value: state.name
			}));
		}
		return options;
	};
}

export default CountryHelper;
