import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Form, Grid, Dropdown, Header
} from 'semantic-ui-react';

import CountryHelper from '../../lib/helpers/country';
import OrganizationInfoHelper from '../../lib/helpers/pages/organizationInfo';

import DonationCategories from '../../Components/DonationCategories/DonationCategories';
import { OrganizationInfoProps, optionsFeedingAmerica } from '../../lib/models/pages/organizationInfo';

import UserInfoContext from '../../contexts/userInfo';
import { UserInfo } from '../../lib/models/userInfo';

const OrganizationInfo = ({
	address, categories, contactName,
	contactPhone, contactEmail, isEditMode,
	onChange, partnerInformation, taxId,
	websiteUrl
}: OrganizationInfoProps) => {
	const { t } = useTranslation();

	const userInfo: UserInfo = useContext(UserInfoContext);
	const statesOptions = CountryHelper.getStatesByCountry(t('countryName'));

	const onPhoneChange = (e: React.SyntheticEvent) => {
		OrganizationInfoHelper.onPhoneChange(e, onChange, userInfo);
	}

	const onZipCodeChange = (e: React.SyntheticEvent) => {
		OrganizationInfoHelper.onZipCodeChange(e, t, onChange);
	}

	return (
		<>
			<Header>Organization Info</Header>
			<Grid columns={2}>
				<Grid columns={2}>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'>
							<b>Name</b>
						</Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='user'
									iconPosition='left'
									placeholder='Contact person name'
									value={contactName}
									onChange={(e) => onChange('contactName', e.target.value)}
								/>
							) : (
								<div className='label-value'>{contactName || '-'}</div>
							)}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'><b>Phone number</b></Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='phone'
									iconPosition='left'
									placeholder='Phone number'
									value={contactPhone}
									onChange={onPhoneChange}
								/>
							) : (
								<div className='label-value'>
									{`${t('phoneCode')} ${OrganizationInfoHelper.getFormattedPhone(contactPhone, userInfo) || '-'}`}
								</div>
							)}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'><b>Email address</b></Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='mail'
									iconPosition='left'
									placeholder='Email address'
									value={contactEmail}
									onChange={(e) => onChange('contactEmail', e.target.value)}
								/>
							) : <div className='label-value'>{contactEmail || '-'}</div>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'><b>Tax ID</b></Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='money bill alternate'
									iconPosition='left'
									placeholder='501C3 Tax ID (XX-XXXXXXX)'
									value={taxId}
									onChange={(e) => onChange('taxId', e.target.value)}
									disabled={userInfo.isEU}
								/>
							) : <div className='label-value'>{taxId || '-'}</div>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'><b>Website</b></Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='linkify'
									iconPosition='left'
									placeholder='URL of non-profit'
									value={websiteUrl}
									onChange={(e) => onChange('websiteUrl', e.target.value)}
								/>
							)
								: (
									<div className='website-url-div'>
										<a
											className='label-value'
											href={websiteUrl}
											target='_blank'
											rel='noreferrer'
										>
											{websiteUrl || '-'}
										</a>
									</div>
								)}
						</Grid.Column>
					</Grid.Row>
					{!userInfo.isEU ? (
						<Grid.Row className='grid-row'>
							<Grid.Column className='labels'><b>Feeding America Partner</b></Grid.Column>
							<Grid.Column>
								{isEditMode ? (
									<Dropdown
										fluid
										selection
										options={optionsFeedingAmerica}
										placeholder='Is Feeding America partner?'
										value={partnerInformation}
										onChange={(_e, { value }) => onChange('partnerInformation', value)}
									/>
								) : (
									<div className='label-value'>
										{OrganizationInfoHelper.getIsFeedingAmerica(partnerInformation)}
									</div>
								)}
							</Grid.Column>
						</Grid.Row>
					) : <></>}
				</Grid>

				<Grid columns={2} style={{ marginTop: '0px' }}>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'>
							<b>Line 1</b>
						</Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='address book'
									iconPosition='left'
									placeholder='Address Line 1'
									value={address.line1}
									onChange={(e) => onChange('address.line1', e.target.value)}
								/>
							) : <div className='label-value'>{address.line1 || '-'}</div>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'>
							<b>Line 2</b>
						</Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='address book'
									iconPosition='left'
									placeholder='Address Line 2 (optional)'
									value={address.line2}
									onChange={(e) => onChange('address.line2', e.target.value)}
								/>
							) : <div className='label-value'>{address.line2 || '-'}</div>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'>
							<b>City</b>
						</Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='address book'
									iconPosition='left'
									placeholder='City'
									value={address.city}
									onChange={(e) => onChange('address.city', e.target.value)}
								/>
							) : <div className='label-value'>{address.city || '-'}</div>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'><b>{t('state')}</b></Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Select
									fluid
									options={statesOptions}
									placeholder={t('state')}
									value={address.state}
									search
									onChange={(_e, { value }) => onChange('address.state', value)}
								/>
							) : <div className='label-value'>{address.state || '-'}</div>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'><b>{t('zipCode')}</b></Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<Form.Input
									fluid
									icon='address book'
									iconPosition='left'
									placeholder='Zip'
									value={address.zipCode}
									onChange={onZipCodeChange}
								/>
							) : <div className='label-value'>{address.zipCode || '-'}</div>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='grid-row'>
						<Grid.Column className='labels'><b>Donation Categories</b></Grid.Column>
						<Grid.Column>
							{isEditMode ? (
								<DonationCategories
									categories={categories}
									onChange={
										(_e: React.SyntheticEvent, { value }: { value: any }) => onChange('categories', value.sort())
									}
								/>
							) : <div className='label-value'>{categories.join(', ') || '-'}</div>}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Grid>
		</>
	);
};

export default OrganizationInfo;
