/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
import { GetReportDonationsParams } from '../../models/pages/viewDonations';

const ONE_YEAR_IN_DAYS: number = 365;
const ONE_WEEK_IN_DAYS: number = 7;

export abstract class GetDonationsParams {
	protected params: GetReportDonationsParams;

	constructor(params: GetReportDonationsParams) {
		this.params = params;
	}

	public abstract getParams(): GetReportDonationsParams;
}

export class GetDailyDonationParams extends GetDonationsParams {
	constructor(timestampValue: number, sitesValues: string[], categoryType: string, donationType: 'daily' | 'monthly') {
		super({
			timestamp: timestampValue,
			siteNames: JSON.stringify(sitesValues),
			categoryType,
			donationType,
			query: 'before',
			days: ONE_WEEK_IN_DAYS
		})
	}

	public getParams = (): GetReportDonationsParams => this.params;
}

export class GetMonthlyDonationParams extends GetDonationsParams {
	constructor(timestampValue: number, sitesValues: string[], categoryType: string, donationType: 'daily' | 'monthly') {
		super({
			timestamp: timestampValue,
			siteNames: JSON.stringify(sitesValues),
			categoryType,
			donationType,
			query: 'after',
			days: ONE_YEAR_IN_DAYS
		})
	}

	public getParams = (): GetReportDonationsParams => this.params;
}
