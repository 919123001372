import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Button, Form, Grid, Icon, Label, Segment
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

import { DONATIONS, INITIAL_STATES, WEIGHTS } from '../../lib/constants';
import ReportDonationHelper from '../../lib/helpers/pages/reportDonation';
import { PickupSiteDetails } from '../../lib/models/communityPartner';
import { NewDonationEntryProps } from '../../lib/models/pages/reportDonations';

const NewDonationEntry: React.FC<NewDonationEntryProps> = (props) => {
	const { partner, handleAddDonationEntry } = props;
	const { t } = useTranslation();
	const [donationEntry, setDonationEntry] = useState({
		...INITIAL_STATES.DONATION_ENTRY
	});
	const [isInvalid, setIsInvalidEntry] = useState(false);

	const categories: string[] = useMemo(() => {
		const partnerCategories = partner.categories || [];
		return ReportDonationHelper.reduceCategoryOptionsToDonationOptions(
			partnerCategories
		);
	}, [partner.categories]);

	const onAddDonationEntry = () => {
		if (!ReportDonationHelper.isValidEntry(donationEntry)) {
			return setIsInvalidEntry(() => true);
		}

		setIsInvalidEntry(() => false);
		handleAddDonationEntry(donationEntry);
		return setDonationEntry(() => ({ ...INITIAL_STATES.DONATION_ENTRY }));
	};

	const onError = (value: string | number) => ReportDonationHelper.onErrorMessage(isInvalid, value);

	return (
		<Segment>
			<Form>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={3}>
							<Form.Select
								placeholder='Select Site'
								fluid
								search
								selection
								value={donationEntry.site}
								onChange={(_event, { value }) => setDonationEntry((prev) => ({
									...prev,
									site: value as string
								}))}
								options={
									partner.sites?.map((site: PickupSiteDetails) => ({
										key: site.name,
										text: site.name,
										value: site.name
									})) || []
								}
								error={onError(donationEntry.site)}
							/>
						</Grid.Column>
						<Grid.Column width={3}>
							<Form.Select
								placeholder='Select Donation Type'
								fluid
								search
								selection
								value={donationEntry.type}
								onChange={(_event, { value }) => setDonationEntry((prev) => ({
									...prev,
									type: value as string
								}))}
								options={
									Object.keys(DONATIONS.TYPES)?.map((type: string) => ({
										key: type,
										text: DONATIONS.TYPES[type],
										value: type
									})) || []
								}
								error={onError(donationEntry.type)}
							/>
						</Grid.Column>
						<Grid.Column width={3}>
							<DatePicker
								selected={
									donationEntry.donatedAt
										? new Date(donationEntry.donatedAt)
										: null
								}
								onChange={(value) => setDonationEntry((prev) => ({
									...prev,
									donatedAt: new Date(value!).getTime()
								}))}
								dateFormat={
									donationEntry.type === 'monthly' ? 'MM/yyyy' : 'MM/dd/yyyy'
								}
								maxDate={new Date()}
								showMonthYearPicker={donationEntry.type === 'monthly'}
								placeholderText='Select Date'
							/>
							{onError(donationEntry.donatedAt!) && (
								<Label basic color='red' pointing='above'>
									Please select a date
								</Label>
							)}
						</Grid.Column>
						<Grid.Column width={3}>
							<Form.Select
								placeholder='Select Category'
								fluid
								search
								selection
								value={donationEntry.category}
								onChange={(_event, { value }) => setDonationEntry((prev) => ({
									...prev,
									category: value as string
								}))}
								options={
									categories?.map((category: string) => ({
										key: category,
										text: category,
										value: category
									})) || []
								}
								error={onError(donationEntry.category)}
							/>
						</Grid.Column>
						<Grid.Column width={3}>
							<Form.Input
								fluid
								type='Number'
								min={0}
								max={donationEntry.type === 'daily' ? WEIGHTS.DAILY.MAX : WEIGHTS.MONTHLY.MAX}
								placeholder={t('weightUnit')}
								value={donationEntry.weight || ''}
								onChange={(_event, { value }) => setDonationEntry((prev) => ({
									...prev,
									weight: parseInt(value, 10)
								}))}
								error={onError(donationEntry.weight!)}
							/>
						</Grid.Column>
						<Grid.Column width={1}>
							<Button icon onClick={onAddDonationEntry}>
								<Icon name='plus' />
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

export default NewDonationEntry;
