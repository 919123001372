import { CATEGORY_OPTION_TO_DONATION_OPTION } from '../../constants/categories/categories';
import { WEIGHTS } from '../../constants';
import { DonationEntry } from '../../models/pages/reportDonations';

class ReportDonationHelper {
	static reduceCategoryOptionsToDonationOptions = (categories: string[]): string[] => (
		Array.from(
			categories.reduce((prev, category) => (
				prev.add(CATEGORY_OPTION_TO_DONATION_OPTION[category])
			), new Set<string>())
		)
	)

	static isValidEntry = (entry: DonationEntry): boolean => {
		let valid: boolean = false;

		const allFieldsHaveValues: boolean = Object.values(entry).every((value) => !!value);
		const validWeights: boolean = entry.type === 'daily' ? (
			entry.weight! >= WEIGHTS.DAILY.MIN && entry.weight! <= WEIGHTS.DAILY.MAX
		) : (
			entry.weight! >= WEIGHTS.MONTHLY.MIN && entry.weight! <= WEIGHTS.MONTHLY.MAX
		);

		valid = allFieldsHaveValues && validWeights;

		return valid;
	}

	static onErrorMessage = (isInvalid: boolean, value: string | number): Object | null => {
		const error: boolean = isInvalid && !value;

		if (!error) return null;
		return {
			content: 'value must be provided',
			pointing: 'above'
		}
	}
}

export default ReportDonationHelper;
