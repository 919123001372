import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Profile from '../../Pages/Profile/Profile';
import Home from '../../Pages/Home/Home';
import ReportDonation from '../../Pages/ReportDonation/ReportDonation';
import ViewDonations from '../../Pages/ViewDonations/ViewDonations';
import AcknowledgementForm from '../../Pages/AcknowledgementForm/AcknowledgementForm';

const RoutesSwitcher: React.FC = () => {
	interface RouteProps {
		readonly path: string;
		readonly element: () => JSX.Element;
	}

	const ROUTES: RouteProps[] = [
		{ path: '/profile', element: Profile },
		{ path: '/donation/add', element: ReportDonation },
		{ path: '/donation/view', element: ViewDonations },
		{ path: '/acknowledgement', element: AcknowledgementForm },
		{ path: '/', element: Home }
	];

	return (
		<Routes>
			{ ROUTES.map((route) => {
				const Element = route.element;
				return <Route path={route.path} key={route.path} element={<Element />} />
			})}
		</Routes>
	)
}

export default RoutesSwitcher;
