import { OnboardingOptions } from '../models/components/communityPartnerStatus';
import { PARTNERS, PARTNER_STATUS } from './options';

const onboardingOptions: OnboardingOptions[] = [
	{
		value: PARTNER_STATUS.ONBOARDING,
		label: 'Onboarding',
		color: 'yellow'
	},
	{
		value: PARTNER_STATUS.ACTIVE,
		label: 'Active',
		color: 'green'
	},
	{
		value: PARTNER_STATUS.INACTIVE,
		label: 'Inactive',
		color: 'grey'
	}
];

const optionsFeedingAmerica = [
	{ key: 'yes', text: 'Yes', value: PARTNERS.FEEDING_AMERICA },
	{ key: 'no', text: 'No', value: PARTNERS.OTHER }
];

export {
	onboardingOptions, optionsFeedingAmerica
}
