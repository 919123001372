export const DAYS_MAPPING: Record<number, string> = {
	0: 'Sunday',
	1: 'Monday',
	2: 'Tuesday',
	3: 'Wednesday',
	4: 'Thursday',
	5: 'Friday',
	6: 'Saturday'
}

export const MONTHS_MAPPING: Record<number, string> = {
	1: 'Jan',
	2: 'Feb',
	3: 'Mar',
	4: 'Apr',
	5: 'May',
	6: 'Jun',
	7: 'Jul',
	8: 'Aug',
	9: 'Sep',
	10: 'Oct',
	11: 'Nov',
	12: 'Dec'
}

export enum Months {
	JAN = 1,
	FEB = 2,
	MAR = 3,
	APR = 4,
	MAY = 5,
	JUN = 6,
	JUL = 7,
	AUG = 8,
	SEP = 9,
	OCT = 10,
	NOV = 11,
	DEC = 12
}

export const ONE_DAY_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000;
export const ONE_SECOND_IN_MILLISECONDS: number = 1 * 1000;
export const NUM_DAYS_PER_WEEK: number = 7;
