import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { SESSION } from '../../lib/constants/index';

const SessionTimeout = () => {
	const [state, setState] = useState<string>('Active')
	const [count, setCount] = useState<number>(0)
	const [remaining, setRemaining] = useState<number>(0)
	const viewSessionInfo = false;

	const onIdle = async () => {
		setState('Idle')
		await Auth.signOut();
		window.location.reload();
	}

	const onActive = () => {
		setState('Active')
	}

	const onAction = () => {
		setCount(count + 1)
	}

	const { getRemainingTime } = useIdleTimer({
		onIdle,
		onActive,
		onAction,
		timeout: SESSION.durationMs,
		throttle: 500
	})

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000))
		}, 500)

		return () => {
			clearInterval(interval)
		}
	})

	return (
		<>
			{viewSessionInfo ? (
				<>
					<h2>
						Current State:&nbsp;
						{state}
					</h2>
					<h2>
						Action Events:
						{count}
					</h2>
					<h2>
						{remaining}
						&nbsp;seconds remaining
					</h2>
				</>
			) : (
				<></>
			)}
		</>
	);
}

export default SessionTimeout;
