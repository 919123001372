import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Amplify, Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { setFetchMethod, enable as enableDarkMode } from 'darkreader';

import {
	COGNITO, COOKIES, INITIAL_STATES, LOCALE_STORAGE
} from '../../lib/constants/index';
import SessionTimeout from '../SessionTimeout/SessionTimeout';
import UserInfoContext from '../../contexts/userInfo';
import AppContext from '../../contexts/app';

import RoutesSwitcher from './Router';
import Layout from './Layout';
import SignIn from '../../Pages/SignIn/SignIn';
import config from '../../lib/config';

import './App.css';
import { AlertProps, AlertType, ALERT_TIME_OUT } from '../../lib/models/components/alert';
import CommunityTrackerApi from '../../api/communityTracker';
import Alert from '../Alert/Alert';
import { isEU } from '../../lib/common';
import { Country } from '../../lib/constants/country';
import DARK_MODE_THEME from '../../lib/constants/darkmode';
import AcknowledgementFormHelper from '../../lib/helpers/pages/acknowledgementForm';

axios.interceptors.request.use(async (request) => {
	const currentSession = await Auth.currentSession();
	const token = currentSession.getIdToken().getJwtToken();
	request.headers.Authorization = token;

	return request;
});

const App = () => {
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const [userInfo, setUserInfo] = useState(INITIAL_STATES.USER_INFO);
	const [apiUrl, setApiUrl] = useState('');
	const [alertMessage, setAlertMessage] = useState(INITIAL_STATES.ALERT_MESSAGE);
	const [documentsToSignChecked, setDocumentsToSignChecked] = useState(false);
	let amplifyConfigured: boolean = false;

	const configureAmplify = async () => {
		if (!amplifyConfigured) {
			const amplifyAuthConfig = await config.authConfig;
			Amplify.configure({
				Auth: amplifyAuthConfig
			});
			amplifyConfigured = true;
		}
	}

	const getApiUrl = async () => {
		const url = await config.apiUrl;
		setApiUrl(url);
	}

	useEffect(() => {
		getApiUrl();
		setFetchMethod(window.fetch);
		enableDarkMode(DARK_MODE_THEME);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const refreshUserSession = async () => {
		try {
			await configureAmplify();
			const currentSession = await Auth.currentSession();
			const cognitoUser = await Auth.currentAuthenticatedUser();

			cognitoUser.refreshSession(currentSession.getRefreshToken(), (_: any, session: any) => {
				const { idToken } = session;
				const { payload } = idToken;
				setUserInfo({
					email: payload.email,
					foodbankId: payload['custom:foodbank_id'],
					country: payload['custom:country'],
					loginStatus: COGNITO.STATUSES.LOGGED_IN,
					name: payload.name,
					title: payload['custom:title'],
					phone: payload.phone_number,
					tokenExpiry: payload.exp * 1000,
					token: idToken.getJwtToken(),
					userId: payload['cognito:username'],
					isEU: isEU(payload['custom:country'] as Country)
				});
			});
		} catch (error: any) {
			console.error('refresh:', error);
			setUserInfo((prev) => ({
				...prev,
				loginStatus: COGNITO.STATUSES.LOGGED_OUT
			}));
		}
	}

	useEffect(() => {
		const updateTranslationCountry = () => {
			// change the translations based on the country
			i18n.changeLanguage(userInfo.country);
			// set the local storage to save the partner profile country
			window.localStorage.setItem(LOCALE_STORAGE, userInfo.country);
		}

		const countryInStorage: string | null = window.localStorage.getItem(LOCALE_STORAGE);
		if (userInfo.country !== countryInStorage) updateTranslationCountry();
	}, [i18n, userInfo.country]);

	useEffect(() => {
		refreshUserSession();
		const intervalId = setInterval(refreshUserSession, COOKIES.TIMERS_REFRESH_TOKEN_DURATION);
		return () => {
			clearInterval(intervalId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAlert = ({ header, content, type }: AlertProps) => {
		setAlertMessage(() => ({ header, content, type }));

		setTimeout(() => (
			setAlertMessage(() => INITIAL_STATES.ALERT_MESSAGE)
		), ALERT_TIME_OUT[type]);
	}

	useEffect(() => {
		// sign the missing documents if any
		const checkDocumentsToSign = async () => {
			try {
				const { data } = await (new CommunityTrackerApi(apiUrl))
					.getPartnerProfile(userInfo.foodbankId);
				const taxYear: string = AcknowledgementFormHelper.eligibleTaxYear(data.taxDocuments);
				if (taxYear) {
					handleAlert({
						header: 'Action needed',
						content: 'You have documents to sign',
						type: AlertType.INFO
					})
					navigate('/acknowledgement');
				} else {
					setDocumentsToSignChecked(() => true);
				}
			} catch (error: any) {
				console.error('failed to check documents to sign', error?.message);
			}
		}

		if (apiUrl && userInfo.foodbankId && !documentsToSignChecked) checkDocumentsToSign();
	}, [apiUrl, documentsToSignChecked, navigate, userInfo.foodbankId]);

	if (userInfo.loginStatus === COGNITO.STATUSES.LOGGED_OUT) {
		return <SignIn onSignIn={refreshUserSession} />;
	}

	return (
		<>
			<AppContext.Provider
				value={{
					apiUrl,
					handleAlert,
					communityTrackerApi: new CommunityTrackerApi(apiUrl)
				}}
			>
				<UserInfoContext.Provider value={userInfo}>
					{alertMessage.header && <Alert {...alertMessage} />}
					<Layout>
						<SessionTimeout />
						<RoutesSwitcher />
					</Layout>
				</UserInfoContext.Provider>
			</AppContext.Provider>
		</>
	)
}

export default App;
