import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Table } from 'semantic-ui-react';

import { TaxDocumentsProps } from '../../lib/models/pages/taxDocuments';
import TaxDocumentsHelper from '../../lib/helpers/pages/taxDocuments';
import AppContext, { AppContextObject } from '../../contexts/app';

import './Profile.css';

const TaxDocuments = ({ taxDocuments, partnerName }: TaxDocumentsProps) => {
	const app: AppContextObject = useContext(AppContext);
	const { t } = useTranslation();
	const sortedTaxDocuments = taxDocuments.sort((a, b) => b.year - a.year);

	const onDownload = (e: React.SyntheticEvent, s3Key: string, year: number) => {
		TaxDocumentsHelper.download(e, app, s3Key, `${partnerName} - ${year}`);
	}

	return (
		<>
			<span>
				<Header className='donation-form'>{t('taxDocument')}</Header>
			</span>
			<br />
			{sortedTaxDocuments.length > 0 ? (
				<Table basic='very'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>File Name</Table.HeaderCell>
							<Table.HeaderCell>Year</Table.HeaderCell>
							<Table.HeaderCell>Upload Date</Table.HeaderCell>
							<Table.HeaderCell>Uploaded By</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{sortedTaxDocuments.map(({
							s3Key, year, createdAt, createdBy
						}) => (
							<Table.Row key={s3Key}>
								<Table.Cell>{t('taxDocument')}</Table.Cell>
								<Table.Cell>
									<a
										href='/'
										onClick={(e) => onDownload(e, s3Key, year)}
										target='_blank'
										rel='noreferrer'
									>
										{`${partnerName} - ${year}`}
									</a>
								</Table.Cell>
								<Table.Cell>{year}</Table.Cell>
								<Table.Cell>{TaxDocumentsHelper.getFormattedDate(createdAt)}</Table.Cell>
								<Table.Cell>{createdBy}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			) : null}
		</>
	);
};

export default TaxDocuments;
