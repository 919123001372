import COGNITO from '../cognito';
import { UserInfo } from '../../models/userInfo';

const USER_INFO: UserInfo = {
	email: '',
	foodbankId: '',
	country: '',
	loginStatus: COGNITO.STATUSES.UNKNOWN,
	name: '',
	title: '',
	phone: '',
	token: '',
	tokenExpiry: 0,
	userId: '',
	isEU: false
}

export default USER_INFO;
