import React, { useEffect, useMemo, useState } from 'react';

import { Checkbox } from 'semantic-ui-react';

import IRC_AGREEMENTS from '../../lib/constants/ircAgreements';

interface IRCAgreementProps {
	onConfirmationChange: Function;
}

const IRCAgreement: React.FC<IRCAgreementProps> = (props) => {
	const { onConfirmationChange } = props;
	const [checkboxes, setCheckboxes] = useState(Array(IRC_AGREEMENTS.length).fill(false));

	const handleChecked = (value: boolean, index: number) => {
		setCheckboxes((prev) => {
			const updated: boolean[] = [...prev];
			updated[index] = value;
			return updated;
		});
	}

	const allBoxesChecked: boolean = useMemo(() => (
		checkboxes.every((checkbox: boolean) => !!checkbox)
	), [checkboxes]);

	useEffect(() => {
		onConfirmationChange(() => allBoxesChecked);
	}, [allBoxesChecked, onConfirmationChange]);

	return (
		<div className='irc-agreement-wrapper'>
			Donee is pleased to make the following statements in accordance with
			Internal Revenue Code (IRC) §170:

			{ IRC_AGREEMENTS.map((agreement, index) => (
				<div key={agreement}>
					<Checkbox
						label={agreement}
						checked={checkboxes[index]}
						onChange={(_event, { checked }) => handleChecked(checked!, index)}
					/>
				</div>
			))}
		</div>
	)
}

export default IRCAgreement;
