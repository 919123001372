import { Country, Region, COUNTRY_REGION } from './constants/country';
import { EU_OPTIONS, NA_OPTIONS } from './constants/categories/categories';

import { PARTNERS } from './constants';

const FALLBACK_REGION = Region.NA;

const getIsFeedingAmerica = (partnerInformation: string) => {
	if (partnerInformation === PARTNERS.FEEDING_AMERICA) {
		return 'Yes';
	}
	if (partnerInformation === PARTNERS.OTHER) {
		return 'No';
	}
	return '-';
};

const getRegion = (country: Country) => {
	const region = COUNTRY_REGION[country];
	if (region !== undefined) {
		return region;
	}
	return FALLBACK_REGION;
}

const isEU = (country: Country): boolean => getRegion(country) === Region.EU;

const getCategoryOptions = (country: Country) => {
	const options = isEU(country) ? EU_OPTIONS : NA_OPTIONS
	return options;
}

export { getIsFeedingAmerica, isEU, getCategoryOptions };
