import React, { useContext } from 'react';

import { Dropdown } from 'semantic-ui-react';
import { DonationCategoriesProps } from '../../lib/models/components/donationsCategories';
import UserInfoContext from '../../contexts/userInfo';
import { UserInfo } from '../../lib/models/userInfo';
import { getCategoryOptions } from '../../lib/common';
import { Country } from '../../lib/constants/country';

const DonationCategories = ({
	categories
}: // onChange,
	DonationCategoriesProps) => {
	const userInfo: UserInfo = useContext(UserInfoContext);
	const categoryOptions = getCategoryOptions(userInfo.country as Country);
	const categoryOptionsObject = categoryOptions.map((option) => ({
		key: option,
		text: `${option[0].toUpperCase()}${option.slice(1)}`,
		value: option
	}));

	const categoriesValue = categories.filter((category) => categoryOptions.includes(category));

	return (
		<Dropdown
			fluid
			multiple
			selection
			options={categoryOptionsObject}
			placeholder='Select the categories the foodbank picks up from'
			value={categoriesValue}
		/>
	);
};

export default DonationCategories;
