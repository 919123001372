import { DonationEntry } from '../../models/pages/reportDonations';

const DONATION_ENTRY: DonationEntry = {
	site: '',
	type: '',
	category: '',
	donatedAt: undefined,
	weight: undefined
}

export default DONATION_ENTRY;
