import React, { useMemo } from 'react'

import CommunityPartner from '../../lib/models/communityPartner';

interface DoneeInformationProps {
	partner: CommunityPartner;
	taxYear: string;
}

const DoneeInformation: React.FC<DoneeInformationProps> = ({ partner, taxYear }) => {
	const partnerAddress: string | undefined = useMemo(() => {
		if (!partner.address) return undefined;

		const {
			line1,
			line2,
			city,
			state,
			zipCode
		} = partner.address;

		let address: string = `${city}, ${state} ${zipCode}`;

		if (line2) address = `${line2}, ${address}`;
		if (line1) address = `${line1}, ${address}`;

		return address;
	}, [partner]);

	const donationPeriod: string = useMemo(() => (
		`01/01/${taxYear} - 12/31/${taxYear}`
	), [taxYear]);

	return (
		<div>
			Donee Information:
			<div>
				<div className='donee-information-partner-name'>
					{`Name: ${partner?.foodbankName}`}
				</div>
				<div className='donee-information-address'>
					{`Address: ${partnerAddress}`}
				</div>
			</div>
			{`Donation Period: ${donationPeriod}`}
		</div>
	)
}

export default DoneeInformation;
