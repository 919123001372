import React from 'react';

import { Grid, Input, Label } from 'semantic-ui-react';
/* eslint-disable import/no-unresolved */
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

import CommunityPartnerStatus from '../CommunityPartnerStatus/CommunityPartnerStatus';

import CommunityPartnerNameHelper from '../../lib/helpers/components/communityPartnerName';
import { CommunityPartnerNameProps } from '../../lib/models/components/communityPartnerName';

const CommunityPartnerName = ({
	communityPartnerName,
	communityPartnerStatus,
	isEditMode,
	onChange
}: CommunityPartnerNameProps) => {
	if (isEditMode) {
		return (
			<Grid columns={1}>
				<Grid.Column style={{ paddingTop: '20px' }}>
					<div className='align-foodbankname'>
						<Label>
							Organization name
							<Input
								id='foodbank-name'
								name='foodbank-name'
								className='foodbank-input'
								placeholder='Organization name'
								value={communityPartnerName}
								onChange={(e) => onChange('foodbankName', e.target.value)}
							/>
						</Label>
						<Label>
							<span className='status-text'>Status</span>
							<div className='status-edit'>
								<CommunityPartnerStatus
									communityPartnerStatus={communityPartnerStatus}
									classNames='dropdown-padding'
								/>
							</div>
						</Label>
					</div>
				</Grid.Column>
			</Grid>
		);
	}

	const statusObj = CommunityPartnerNameHelper.getStatusObj(communityPartnerStatus);
	return (
		<div>
			<span className='foodbank-name-text'>{communityPartnerName}</span>
			<Label
				as='div'
				tag
				color={statusObj.color as SemanticCOLORS}
				style={{ cursor: 'default' }}
			>
				{statusObj.label}
			</Label>
		</div>
	);
};

export default CommunityPartnerName;
