import { AppContextObject } from '../../../contexts/app';
import { PickupSiteDetails } from '../../models/communityPartner';
import { AlertType } from '../../models/components/alert';
import { SiteOption, SiteDetail, SiteQueryParams } from '../../models/siteDetail'

class SiteSearchDropDownHelper {
	/* eslint-disable implicit-arrow-linebreak */
	static mapSiteDetails = (data: PickupSiteDetails[]): SiteOption[] =>
		data
			.map(({ name, type }) => ({
				key: name, value: name, text: name, type
			}));

	/* eslint-disable implicit-arrow-linebreak */
	static mapSites = (data: SiteDetail[]): SiteOption[] =>
		data
			.map(({ name, type }) => ({
				key: name, value: name, text: name, type
			}));

	static getSites = async (
		app: AppContextObject, queryParams: SiteQueryParams
		/* eslint-disable consistent-return */
	) => {
		try {
			/* eslint-disable indent */
			const { data } = await app.communityTrackerApi.getSites(queryParams);
			if (data) {
				return data;
			}
		} catch (error: any) {
			app.handleAlert({
				header: 'Error getting community partner data',
				content: error?.message,
				type: AlertType.ERROR
			});
		}
	}

	static getValues = (sites: PickupSiteDetails[]) =>
		sites.map(({ name }: { name: string }) => name);

	static getOptions = async (
		app: AppContextObject, country: string,
		searchQueryInput: string, setFetching: Function, setOptions: Function,
		stickyOptions: SiteOption[], t: Function
	) => {
		if (!searchQueryInput) {
			setOptions(stickyOptions);
			return;
		}

		setFetching(true);
		/* eslint-disable indent */
		const sites = await
			this.getSites(app, {
				searchTerm: searchQueryInput,
				countryName: t('countryName'),
				countryCode: country
			});

		if (sites) {
			const options = this.mapSites(sites);
			setOptions(options);
		}
		setFetching(false);
	}
}

export default SiteSearchDropDownHelper;
