import React from 'react';
import CommunityTrackerApi from '../api/communityTracker';
import { AlertProps } from '../lib/models/components/alert';

export interface AppContextObject {
	readonly apiUrl: string;
	readonly handleAlert: (_props: AlertProps) => void;
	readonly communityTrackerApi: CommunityTrackerApi;
}

const appContextObject: AppContextObject = {
	apiUrl: '',
	handleAlert: () => {},
	communityTrackerApi: new CommunityTrackerApi('')
}

const AppContext = React.createContext(appContextObject);

export default AppContext;
