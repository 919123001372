import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';

import CommonHelper from '../../lib/helpers/common';
import DateTimeHelper from '../../lib/helpers/dateTime';
import ViewDonationsHelper from '../../lib/helpers/pages/viewDonations';
import { WeeklyViewProps } from '../../lib/models/pages/viewDonations';
import './ViewDonations.css';

const WeeklyView: React.FC<WeeklyViewProps> = ({
	onPrevClick, onNextClick, data, startingTimestamp, endingTimestamp
}: any) => {
	const datePartitions = ViewDonationsHelper.getDatePartitions(startingTimestamp, endingTimestamp);
	const dateHeaders = datePartitions.map(DateTimeHelper.getFormattedDate);
	const donationsByTimestamp = ViewDonationsHelper.getDonationsByTimestamp(data, datePartitions);

	const tableHeaders = (): JSX.Element => (
		<Table.Header>
			<Table.Row textAlign='center'>
				<Table.HeaderCell>
					Sites
				</Table.HeaderCell>
				<Table.HeaderCell className='donation-week-switch'>
					<Label as='a' onClick={onPrevClick} color='black'>
						<Icon name='angle double left' />
						Previous Week
					</Label>
				</Table.HeaderCell>
				{dateHeaders?.map((formattedDate) => (
					<Table.HeaderCell key={formattedDate}>
						{formattedDate}
					</Table.HeaderCell>
				))}
				<Table.HeaderCell>
					<Label as='a' onClick={onNextClick} color='black'>
						Next Week
						<Icon name='angle double right' />
					</Label>
				</Table.HeaderCell>
			</Table.Row>
		</Table.Header>
	)

	const donationRowPerPartner = (): JSX.Element => (
		<Table.Row className='donation-main-row-selected'>
			<Table.Cell key='site_name'> All </Table.Cell>
			<Table.Cell key='prev_week' />

			{datePartitions?.map((timestamp) => (
				<Table.Cell key={timestamp}>
					{ CommonHelper.humanizeNumber(
						ViewDonationsHelper.getTotalVolume(donationsByTimestamp[timestamp])
					)}
				</Table.Cell>
			))}

			<Table.Cell key='next_week' />
		</Table.Row>
	)

	const donationRowsPerSite = (): JSX.Element[] => (
		ViewDonationsHelper.getUniqueSites(data)?.map((siteName: string) => (
			<Table.Row key={siteName}>
				<Table.Cell>
					{siteName}
				</Table.Cell>
				<Table.Cell />
				{datePartitions?.map((timestamp) => (
					<Table.Cell key={timestamp}>
						{ CommonHelper.humanizeNumber(
							ViewDonationsHelper.getDonationWeightBySite(
								donationsByTimestamp[timestamp], siteName
							)
						)}
					</Table.Cell>
				))}
				<Table.Cell />
			</Table.Row>
		))
	)

	const tableBody = (): JSX.Element => (
		<Table.Body className='donation-table-body'>
			<>
				{donationRowPerPartner()}
				{donationRowsPerSite()}
			</>
		</Table.Body>
	)

	return (
		<div className='weekly-view-container'>
			<Table celled className='donation-table' fixed>
				{tableHeaders()}
				{tableBody()}
			</Table>
		</div>
	)
}

export default WeeklyView;
