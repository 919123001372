import { Address } from '../foodbank';
import { PARTNERS } from '../../constants';

interface OrganizationInfoProps {
	address: Address;
	categories: string[];
	contactName: string;
	contactPhone: string;
	contactEmail: string;
	isEditMode: boolean;
	onChange: Function;
	partnerInformation: string;
	taxId: string;
	websiteUrl: string;
}

const optionsFeedingAmerica = [
	{ key: 'yes', text: 'Yes', value: PARTNERS.FEEDING_AMERICA },
	{ key: 'no', text: 'No', value: PARTNERS.OTHER }
];

export type { OrganizationInfoProps };
export { optionsFeedingAmerica };
