interface DayValue {
	value: string,
	label: string
}

const DAYS: DayValue[] = [
	{
		value: 'monday', label: 'M'
	},
	{
		value: 'tuesday', label: 'Tu'
	},
	{
		value: 'wednesday', label: 'W'
	},
	{
		value: 'thursday', label: 'Th'
	},
	{
		value: 'friday', label: 'F'
	},
	{
		value: 'saturday', label: 'Sa'
	},
	{
		value: 'sunday', label: 'Sun'
	},
	{
		value: 'na', label: 'N/A'
	}
];

const DAYS_DICT = DAYS.reduce((prev: any, item: DayValue, index: number) => ({
	...prev,
	...{
		[item.value]: {
			label: item.label,
			index
		}
	}
}), {});

export type { DayValue };

export { DAYS, DAYS_DICT };
