import ALERT_MESSAGE from './alertMessage';
import DONATION_ENTRY from './donationEntry';
import COMMUNITY_PARTNER from './communityPartner';
import USER_INFO from './userInfo';

const INITIAL_STATES = {
	ALERT_MESSAGE,
	COMMUNITY_PARTNER,
	DONATION_ENTRY,
	USER_INFO
};

export default INITIAL_STATES;
