import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { PickupDaysProps } from '../../lib/models/components/pickupDays';
import PickupDaysHelper from '../../lib/helpers/components/pickupDays';

const PickupDays = ({
	onChange,
	values,
	partnerStatus,
	siteName
}: PickupDaysProps) => {
	const options = PickupDaysHelper.options(partnerStatus);

	// NOTE: DropdownProps doesn't have a 'value' in its model, hence no typing
	const onChangeDropdown = (_e: React.SyntheticEvent, { value }: any) => {
		PickupDaysHelper.onChangeDropdown(value, onChange);
	};

	return (
		<Dropdown
			fluid
			multiple
			selection
			options={options}
			placeholder={`Select pick up days for ${siteName}`}
			value={values}
			onChange={onChangeDropdown}
		/>
	);
};

export default PickupDays;
