import { isLocalHost } from '../config';

const LOCAL_STORAGE: string = (() => {
	const localeBase = 'ExternalCommunityTrackerLocale';
	if (isLocalHost()) return `${localeBase}-localhost`;

	const domain: string = window.location.hostname.split('.').join('-');
	return `${localeBase}-${domain}`;
})();

export default LOCAL_STORAGE;
