import { DAYS } from '../constants';
import { DayValue } from '../constants/days';

const sortDays = (days: string[]) => {
	// NOTE: no typing is provided for the accumulator as it's type can be inferred below
	const dict = DAYS.reduce((prev: any, item: DayValue, index: number) => ({
		...prev,
		...{
			[item.value]: {
				label: item.label,
				index
			}
		}
	}), {});

	const sortedDaysValues = days.sort((a: string, b: string) => dict[a].index - dict[b].index);
	const sortedDaysLabel = sortedDaysValues.map((day: string) => dict[day].label);
	return [sortedDaysValues, sortedDaysLabel];
};

export default sortDays;
